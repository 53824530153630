import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import { Box, Stack, Typography, useTheme } from "@mui/material";
import CardContainer, {CardLink} from "./CardContainer";
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function Benefits({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])
    
    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])


    const {death_in_service = {}, healthcare = {}, cycle_to_work = {}, metadata = {}} = data    

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Benefits"
            icon={<LoyaltyRoundedIcon/>}
            iconColor={theme.palette.tiles.green}
            metadata={metadata}
        >
            <Stack gap={3} sx={{fontSize:'13px'}}>
                { healthcare?.should_display ? <Grid container>
                    <Grid item xs={6}>
                        <div style={{whiteSpace:'nowrap'}}>
                            Healthcare: <b>
                                {healthcare?.entitlement_name}
                            </b> <b>
                                {healthcare?.has_benefit ? 'Active' : 'Inactive'}
                            </b>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        {healthcare?.has_benefit ? (
                            <CardLink onClick={() => navigate(healthcare?.links?.web_app)}>View</CardLink>
                        ) : (
                            <CardLink onClick={() => navigate(healthcare?.links?.signup)}>Sign Up</CardLink>
                        ) }
                    </Grid>
                </Grid> : ''}

                { cycle_to_work?.should_display ? <Grid container>
                    <Grid item xs={6}>
                        <div style={{whiteSpace:'nowrap'}}>
                            Cycle to work scheme: <b>
                                {cycle_to_work?.entitlement_name}
                            </b> <b>
                                {cycle_to_work?.has_benefit ? 'Active' : 'Inactive'}
                            </b>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        View
                    </Grid>
                </Grid> : ''}

                { death_in_service?.should_display ? <Grid container>
                    <Grid item xs={6}>
                        <div style={{whiteSpace:'nowrap'}}>
                            Death in Service Benefit: <b>
                                {death_in_service?.entitlement_name}
                            </b> <b>
                                {death_in_service?.has_benefit ? 'Active' : 'Inactive'}
                            </b>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        View
                    </Grid>
                </Grid> : ''}

                <Box paddingTop='12px' borderTop={`1px solid ${theme.palette.dividerInverted}`}>
                    <Typography fontSize='12px'>
                        Eligibility for benefits schemes are dependent on restrictions and conditions.
                        Please speak with the People Team for more details.
                    </Typography>
                </Box>

                {/* <Box paddingTop='12px' borderTop={`1px solid ${theme.palette.dividerInverted}`}>
                    <Typography fontSize='12px'>
                        {/*{hclinks.map(link => (
                        {/*    <></>
                        {/*))}
                    </Typography>
                </Box> */}

            </Stack>

        </CardContainer>
    )
}
