import {Box, useTheme} from '@mui/material';

export default function PlaceholderImageTile({url}) {
    const theme = useTheme();
    return (
        <Box borderRadius='8px'
             boxShadow={`0px 2px 10px ${theme.palette.dea.chipFill}`}
             height='100%'
             overflow='hidden'
             sx={{background: `url(${url}) center center no-repeat`, backgroundSize: 'cover', ariaHidden:true }}
             data-url={url}
             className='placeholder-image-tile'
             position='relative'
        >
            <Box className='drag-handle' display={{xs:'none',lg:'block'}} position='absolute' width='100%' height="100%" left='0px' top='0px'/>
        </Box>
    );
}
