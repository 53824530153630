import {Box, Button, Checkbox, FormControlLabel} from '@mui/material';
import ResetTvOutlinedIcon from '@mui/icons-material/ResetTvOutlined';
import {useServiceWorker} from '../../useServiceWorker';
import {useState} from 'react';

export default function ConfigurationMenu({windowWidthValue = 'windowWidth', dashboardTilesValue = 'dashboardTiles'}) {
    const {reloadPage} = useServiceWorker();
    const [staticApi, setStaticApi] = useState(() => {
        const saved = localStorage.getItem('Use-Static-Data');
        return saved === 'true' || false;
    });

    function handleSwitch(e, v) {
        if (window.confirm('The page will reload to apply changes. Continue?')) {
            localStorage.setItem('Use-Static-Data', v);
            setStaticApi(v);
            reloadPage();
        }
    }

    function handleLayoutChange() {
        if (window.confirm('The page will reload to apply changes. Continue?')) {
            localStorage.removeItem(windowWidthValue);
            localStorage.removeItem(dashboardTilesValue);
            reloadPage();
        }
    }

    return (
        <Box display='flex' justifyContent='end' alignItems='center' gap="1rem">
            <Button startIcon={<ResetTvOutlinedIcon/>} variant='text'
                onClick={handleLayoutChange} sx={{textTransform: "none"}}>
                Reset layout
            </Button>
            <FormControlLabel label="Static API"
                control={<Checkbox checked={staticApi} onChange={handleSwitch} />}
            />
        </Box>
    )
}