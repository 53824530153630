import {Alert, Stack, Button, Typography, CircularProgress} from '@mui/material';
import {useApiCallback} from '../../utils/Api';
import {useEffect, useState} from 'react';
import {Placeholder} from './PlaceholderCard';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export default function Announcement({type = 'active', setEdit = null, edit = null}) {
    const [loading, setLoading] = useState(false);
    const [data, onDone] = useState([]);
    const loadAnnouncement = useApiCallback('GET', `/api/announcements/${type}`, {
        setLoading, onDone,
    })
    useEffect(() => {if (!edit) loadAnnouncement()}, [loadAnnouncement, edit])

    const [deleteItem, setDeleteItem] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const deleteAnnouncement = useApiCallback('DELETE', `/api/announcements/${deleteItem}`, {
        onDone: () => {setDeleteItem(null); loadAnnouncement()}, setLoading: setLoadingDelete, onError: () => setDeleteItem(null)
    })
    useEffect(() => {if (deleteItem) deleteAnnouncement();}, [deleteAnnouncement, deleteItem])

    if (loading) 
        return [1, 2].map((v) => <Placeholder sx={{height: '3rem', borderRadius: '4px'}} key={v}/>)

    if (data.length === 0) return <Typography>{setEdit && 'No announcements set.'}</Typography>

    return (
        <Stack gap='1rem'>
            {setEdit && <Typography>Added Announcements</Typography>}
            {data.map((a) => (
                <Alert key={a.announcement_id} severity={a.type} variant='filled'
                    action={setEdit && [
                    <Button color="inherit" variant='text' size="small" onClick={() => setEdit(a)} startIcon={<EditOutlinedIcon/>}>Edit</Button>,
                        <Button color="inherit" variant='text' size="small" onClick={() => setDeleteItem(a.announcement_id)} startIcon={(loadingDelete && deleteItem == a.announcement_id) ? <CircularProgress color="inherit" size='1rem' /> :<DeleteOutlineOutlinedIcon />}>Delete</Button>
                    ]}>
                    {a.message}
                    {setEdit && <Typography variant='caption' display='block'>
                        {new Date(a.start).toLocaleString()} - {new Date(a.end).toLocaleString()}
                    </Typography>}
                </Alert>
            ))}
        </Stack>
    )    
}
