import PostAddIcon from '@mui/icons-material/PostAdd';
import { Box, Button, Stack, useTheme } from "@mui/material";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CardContainer from "./CardContainer";
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function SuggestBusinessImprovements({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Suggest business improvements"
            icon={<PostAddIcon/>}
            iconColor={theme.palette.tiles.orange}
            headerValues={[{
                heading: 'Total suggestions', value: data?.business_improvement_suggestions?.total_this_year
            }]}
            metadata={data?.metadata}
        >
            <Stack gap='1rem' fontSize='12px' textAlign='center' justifyContent='center' height='100%'>
                This form is designed to capture valuable insights and experiences. By documenting what went well and identifying areas for improvement, we aim to enhance our processes and prevent future issues. Your contributions help drive continuous improvement and foster a culture of learning within the organisation.
                <Box display='flex' justifyContent='center'>
                    <Button
                        size='small'
                        disableElevation
                        color='primary'
                        style={{ fontSize: '10px', color: theme.palette.dea.jetBlack, fontWeight: '700' }}
                        endIcon={<ChevronRightOutlinedIcon />}
                        href='https://docs.google.com/forms/d/1AvyT6DqzgPUFGBb6nqv7IPqUev5A3XK__rd4rmgWORs/edit'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Suggest an improvement
                    </Button>
                </Box>
            </Stack>

        </CardContainer>
    )
}