import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function UpcomingTraining({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {upcoming_training = [], total_upcoming_training = '0', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Upcoming Training'
            icon={<WorkspacePremiumOutlinedIcon/>}
            iconColor={theme.palette.tiles.orange}
            headerValues={[{heading: 'Total Trainings', value: total_upcoming_training}]}
            metadata={metadata}
        >
            <Box component='table' aria-label='Table of upcoming trainings' width='100%' fontSize='12px' sx={{tableLayout: 'fixed'}}>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color="gray.500" sx={{textWrap: 'nowrap'}}>
                        <Box component='th' padding='0 0 3px 0' textAlign='left'>TRAINEE</Box>
                        <Box component='th' padding='0 0 3px 0' textAlign='center'>LOCATION</Box>
                        <Box component='th' padding='0 0 3px 0' textAlign='right'>ORGANISER</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {upcoming_training?.map((training) => (
                        <Box component='tr' key={training?.trainee} fontWeight='medium'>
                            <Box component='td' padding='3px 0' textAlign='left'>{training?.trainee}</Box>
                            <Box component='td' padding='3px' textAlign='center'>{training?.location}</Box>
                            <Box component='td' padding='3px 0' textAlign='right'>{training?.organiser}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}
