import dayjs from 'dayjs';

/**
 * Formats a date string to 'MMM D, YYYY'
 * @param {string} dateString - The date string in the format of 'YYYY-MM-DD'
 * @returns {string} The formatted date string in the format of 'MMM D, YYYY'
 */
export function formatDate(dateString) {
    const targetDate = dayjs(dateString);
    return targetDate.format('MMM D, YYYY');
}

/**
 * Calculates the remaining time from now in months and days
 * @param {string} dateString - The date string in the format of 'YYYY-MM-DD'
 * @param {Array} units - The units to include in the result string. Possible values are 'y', 'mo', 'd', 'h', 'mi'
 * @returns {string} The relative time in the format of '3m2d'
 */
export function calculateRelativeTime(dateString, units = ['y', 'mo', 'd', 'h', 'mi']) {
    const targetDate = dayjs(dateString);
    let now = dayjs();

    const diffInYears = units.includes('y') ? targetDate.diff(now, 'year') : 0;

    now = now.add(diffInYears, 'year');
    const diffInMonths = units.includes('mo') ? targetDate.diff(now, 'month') : 0;

    now = now.add(diffInMonths, 'month');
    const diffInDays = units.includes('d') ? targetDate.diff(now, 'day') : 0;

    now = now.add(diffInDays, 'day');
    const diffInHours = units.includes('h') ? targetDate.diff(now, 'hour') : 0;

    now = now.add(diffInHours, 'hour');
    const diffInMinutes = units.includes('mi') ? targetDate.diff(now, 'minute') : 0;

    let result = []
    if (diffInYears > 0) result.push(`${diffInYears}y`);
    if (diffInMonths > 0) result.push(`${diffInMonths}m`);
    if (!diffInYears && diffInMonths < 3) {
        if (diffInDays > 0) result.push(`${diffInDays}d`);
        if (diffInHours > 0) result.push(`${diffInHours}h`);
        if (!diffInDays && diffInHours < 3) {
            if (diffInMinutes > 0) result.push(`${diffInMinutes}m`);
        }
    }

    return result.join(' ');
}

/**
 * Formats a date string to 'MMM D, YYYY (3m2d)' format
 * @param {string} dateString - The date string in the format of 'YYYY-MM-DD'
 * @returns {string} - The formatted date string in the format of 'MMM D, YYYY (3m2d)'
 */
export function formatDateWithRemainingTime(dateString) {
    const formattedDate = formatDate(dateString);
    const relativeTimeFromNow = calculateRelativeTime(dateString, ['y', 'mo', 'd']);
    return `${formattedDate} (${relativeTimeFromNow})`;
}