import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function OnCall({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {opportunities = [], total_opportunities = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Emerging Opportunities'
            icon={<WorkHistoryOutlinedIcon/>}
            iconColor={theme.palette.tiles.darkBlue}
            headerValues={[{heading: 'Total', value: total_opportunities}]}
            metadata={metadata}
        >
            <Stack gap='6px' textAlign='center' fontSize='12px'>
                {opportunities.length ? (
                    opportunities.map((opportunity) => <Box key={opportunity}>{opportunity}</Box>)
                ) : (
                    'No Emerging Opportunities'
                )}
            </Stack>
        </CardContainer>
    )
}