import image1 from '../../images/1.jpg';

export const defaultHeight = 38
export const fullWidthTiles = ['top_image_tile', 'projects', 'contracts', 'gamston_airport_and_dea_hq', 'aircraft_maintenance', 'flights_airports']
export const alwaysAllowedTiles = ['top_image_tile', 'placeholder_image_tile']
export const tiles = [
    {name: 'top_image_tile'},
    {name: "devices_security"},
    {name: "user_details"},
    {name: "tasks"},
    {name: "timesheet"},
    {name: 'placeholder_image_tile', url: image1},
    {name: "flights_airports", h: 45},
    {name: "schengen"},
    {name: "company_cards"},
    {name: "assets_licenses"},
    {name: "business_intelligence"},
    {name: "company_information"},
    {name: "projects"},
    {name: "planning_meetings"},
    {name: "internal_policies"},
    {name: "pilot_information"},
    {name: "vacancies"},
    {name: "wellbeing"},
    {name: "business_improvement_suggestions"},
    {name: "benefits"},
    // {name: "first_aid"},
    // {name: "blog"},
    // {name: "training"},
    // {name: "aircraft"},
]

export const situationalBriefTiles = [
    {name: 'flight_safety'},
    {name: 'weather_report'},
    {name: 'sea_state_in_operating_area'},
    {name: 'mission_systems_updates'},
    {name: 'camo_updates'},
    {name: 'part145_updates'},
    {name: 'look_ahead'},
    {name: 'look_over_the_shoulder'},
    {name: 'contracts'},
    {name: 'gamston_airport_and_dea_hq'},
    {name: 'aircraft_maintenance'},
    {name: 'on_call'},
    {name: 'emerging_opportunities'},
    {name: 'upcoming_issues'},
    {name: 'meetings_and_visitors'},
    // {name: 'lone_workers'}, // Removed temporarily
    {name: 'upcoming_training'},
]

export const gridOptions = {
    alwaysShowResizeHandle: true,
    cellHeight: 10,
    draggable: {handle: ".drag-handle"}
}

/**
 * Load the layout from local storage
 * @param {GridStack} grid
 * @param {String} storageKey 
 */
export function handleLayoutSave(grid = window.myGrid, storageKey = 'dashboardTiles') {
    try {
        const _tiles = grid.save().map(({content, ...tile}) => {
            const el = new DOMParser().parseFromString(content, 'text/html').body.firstChild
            const name = el?.getAttribute('data-tile')

            if (['placeholder_image_tile'].includes(name)) {
                tile.url = el.querySelector('.placeholder-image-tile')?.getAttribute('data-url')
            }

            return {name, ...tile}
        })

        localStorage.setItem(storageKey, JSON.stringify(_tiles))
    } catch (error) {
        console.error('Error saving layout:', error)
    }
}

/**
 * Resize the content of the large tiles to fit the content
 * @param {GridStack} grid
 * @param {string} resizeToContentTilesKey
 */

export function resizeToContentLargeTiles(grid, resizeToContentTilesKey = 'resizeToContentTiles') {
    window[resizeToContentTilesKey] = window[resizeToContentTilesKey] || []

    if (window[resizeToContentTilesKey].length) {
        window[resizeToContentTilesKey].forEach(selector => {
            const tile = document.querySelector(selector)
            if (!tile) {
                console.warn(`Tile not found for selector: ${selector}`)
                return
            }

            const gridItem = tile.closest('.grid-stack-item')
            if (!gridItem) {
                console.warn(`Grid item not found for tile: ${selector}`)
                return
            }

            tile.style.height = 'auto'
            grid.resizeToContent(gridItem)
        })
    }
}

/**
 * Resize the content card container to fit the content
 * @param {HTMLElement} el
 * @param {number} minWidth
 * @param {GridStack} grid
 * @param {string} resizeToContentTilesKey
 */
export function resizeToContentCardContainer(el, minWidth = 630, grid = window.myGrid, resizeToContentTilesKey = 'resizeToContentTiles') {
    function setHeight() {
        const gridStack = el.closest('.grid-stack')
        const contentWrapper = el.closest('.content-wrapper')
        const gridStackItem = el.closest('.grid-stack-item')

        if (!gridStack || !contentWrapper || !gridStackItem) return

        if (gridStack?.offsetWidth <= minWidth) {
            contentWrapper.style.height = 'auto'
            if (grid) {
                grid?.resizeToContent(gridStackItem)
                // to reattach all events
                grid?.disable()
                grid?.enable()
            } else {
                window[resizeToContentTilesKey] = window[resizeToContentTilesKey] || []
                window[resizeToContentTilesKey].push(`[data-tile="${contentWrapper.getAttribute('data-tile')}"]`)
            }
        } else {
            contentWrapper.style.height = '100%'
        }
    }
    window.addEventListener('resize', setHeight)
    setHeight()

    return () => window.removeEventListener('resize', setHeight)
}