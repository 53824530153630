/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import {create} from "zustand";
import {useCallback, useEffect, useMemo} from "react";
import {useLoginStore} from "../Login";
import {useAppConfigStore} from "../AppConfigStore";
import {matchRoutes, useLocation, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";


export const useSideDrawerStore = create((set) => ({
    drawerWidth: 240,
    setDrawerWidth: (drawerWidth) => set({drawerWidth}),
    isClosing: false,
    setIsClosing: (isClosing) => set({isClosing}),
    mobileOpen: false,
    setMobileOpen: (setMobileOpen) => set({setMobileOpen}),
    isOpen: false,
    setIsOpen: (isOpen) => set({isOpen}),
    toggle: () => set((state) => ({isOpen: !state.isOpen})),
    hideBtn: false,
    setHideBtn: (hideBtn) => set({hideBtn}),
}))

export const SideDrawerOpenBtn = () => {
    const {toggle, hideBtn} = useSideDrawerStore();
    if (hideBtn) {
        return ''
    }
    return <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggle}
        sx={{mr: 2}}
    >
        <MenuIcon/>
    </IconButton>
}

const SideDrawer = () => {
    const {drawerWidth, setDrawerWidth, setIsOpen, isOpen, setHideBtn} = useSideDrawerStore();
    const {pages} = useAppConfigStore()
    const loginstore = useLoginStore();
    const navigate = useNavigate();
    const location = useLocation()
    const theme = useTheme();


    const menuItemClick = useCallback((page) => {
        navigate(page.link);
        setIsOpen(true);
    }, [navigate, setIsOpen])

    const links = useMemo(() => {
        if (!loginstore.user) {
            return []
        }
        const links = {}
        Object.keys(pages).forEach(k => {
            const [name, link, , options] = pages[k]
            const icon = options?.icon
            if (link.indexOf(':') > -1) {
                return
            }
            if (options?.hide) {
                return
            }
            if (loginstore.canUser('GET', '#' + link)) {
                links[name] = {name, link, icon}
            }
        })
        return Object.values(links);
    }, [pages, loginstore])

    const onPage = useMemo(() => {
        let onPage = '';
        const some = Object.keys(pages).some(k => {
            const [name, path, ,] = pages[k]
            const r = matchRoutes([{path}], location)
            if (r) {
                onPage = name
                return true
            }
            return false
        })
        if (!some) {
            // default page name to be set if no other name found, prevents empty page selector except on homepage
            let name = location.pathname.replace(/^\//, '')
            name = name.charAt(0).toUpperCase() + name.slice(1); // capitalise first letter
            onPage = name
        }
        return onPage
    }, [location, pages])

    /* @uncommon - @FIXME needs removing for integration to common-code */
    const getLinkStyle = useCallback(name => ({
        backgroundColor: onPage === name ? theme.palette.dea.turquoise : '',
        color: onPage === name ? theme.palette.dea.stealthGreyText : '',
        borderRadius: "5px",
    }), [onPage, theme.palette.dea])

    const getIconStyle = useCallback(name => ({
        color: onPage === name ? theme.palette.dea.stealthGreyText : '',
    }), [onPage, theme.palette.dea])
    const drawer = useMemo(() => <List>
        {links.map(link => (
            <ListItem key={link.name} sx={{borderRadius: "5px", p: "0px", pl: "5px", pr: "5px"}}>
                <ListItemButton onClick={() => menuItemClick(link)} sx={getLinkStyle(link.name)}>
                    <ListItemIcon sx={getIconStyle(link.name)}>
                        {link.icon || <MenuIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={link.name} sx={{whiteSpace: 'nowrap'}}/>
                </ListItemButton>
            </ListItem>
        ))}
    </List>, [links, menuItemClick, getLinkStyle, getIconStyle]);

    useEffect(() => setDrawerWidth(links.length > 1 ? 240 : 0), [setDrawerWidth, links]);
    useEffect(() => setDrawerWidth(isOpen ? 240 : 65), [setDrawerWidth, isOpen, links]);

    useEffect(() => setHideBtn(links.length <= 1), [setHideBtn, links])

    if (links.length <= 1) {
        // don't show the sidebar at all if there's zero or only one thing in it.
        return null
    }

    return (
        <Box
            component="nav"
            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                variant="temporary"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', lg: 'none'},
                    '& .MuiDrawer-paper': {width: drawerWidth},
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{
                    display: {xs: 'none', lg: 'block'},
                    '& .MuiDrawer-paper': {pt: '50px', transition: 'width 0.2s', width: drawerWidth, overflow: 'hidden'},
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default SideDrawer;
