import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import {Stack} from '@mui/material';

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(updateLocale); 

export default function ApiMetadata({metadata}) {
    return (
        <Tooltip title={<Metadata metadata={metadata}/>}>
            <InfoOutlinedIcon aria-label='info about api' color='disabled'/>
        </Tooltip>
    )
}

function Metadata  ({metadata}) {
    const {cache_info={}, data_source='cache', upstream_data_sources=[]} = metadata;

    return (
        <Stack sx={{color:'white'}}>
            {cache_info?.last_updated_timestamp_ms && <span>Updated: {lastUpdated(cache_info.last_updated_timestamp_ms)}</span>}

            {cache_info?.cache_refresh_interval_seconds && <span>Updates: Every {(cache_info.cache_refresh_interval_seconds/60) < 60 ? "30mins" : "2hrs"}
            </span>}
            
            {upstream_data_sources.length && <span>Data from: <span style={{textTransform: 'capitalize'}}>{upstream_data_sources?.map(e => e.split('_').join(' ')).join(', ')}</span>
            </span>}

            <span>Cached: {data_source === 'cache' ? 'Yes' : 'No'}</span>
        </Stack>
    );
};
function lastUpdated(ts) {
    const lastUpdated =  dayjs(ts).format('HH:mm D MMMM')
    const timeAgo =  dayjs(ts).fromNow()
    return `${lastUpdated}, ${timeAgo}`
}