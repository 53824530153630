import {useEffect, useState} from "react";
import {Box} from "@mui/material";

export const useResizeObserver = (ref) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (!ref.current) return
        const obj = ref.current
        const resizeObserver = new ResizeObserver(() => {
            const rect = obj.getBoundingClientRect()
            setWidth(rect.width)
            setHeight(rect.height)
        });
        resizeObserver.observe(obj);
        return () => resizeObserver.disconnect()

        // WE NEED ref.current AS A DEPENDENCY HERE - DO NOT REMOVE IT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, setWidth, setHeight, ref.current])
    return {width, height}
}

export const HidingBox = (props) => {
    const {hide, ...other} = props
    return hide ? <></> : <Box {...other}/>
}
