import {Box, Button, Stack} from "@mui/material";
import ConfigurationMenu from "./ConfigurationMenu";
import {useMemo} from "react";
import MyGridStackSituationalBrief from "./MyGridStackSituationalBrief";
import EditIcon from '@mui/icons-material/Edit';

export default function SituationalBriefTilesContainer() {
    const configMenu = useMemo(() => <ConfigurationMenu windowWidthValue='windowWidth' dashboardTilesValue='dashboardTilesSituationalBrief'/>, []);
    return (
        <Stack gap='1rem'>
            <MyGridStackSituationalBrief/>
            <Box display='flex' alignItems='center' justifyContent='end' gap="1rem">
                <Button
                    target='_blank'
                    rel='noopener noreferrer'
                    variant='text'
                    href='https://docs.google.com/spreadsheets/d/1eZkmq7iQT4cKhOiYrnT74dLrZ3K_uXhffOXnpu9pBQg/edit?gid=0#gid=0'
                    startIcon={<EditIcon/>}
                    >
                        Edit data
                </Button>
                {configMenu}
            </Box>
        </Stack>
    )
}