/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {useMemo} from "react";

export default function GenericSelect(params) {
    let {setValue, hideBlankOption, sx, custom, label} = params;
    let options = params.options || []
    if (options.indexOf('') === -1 && hideBlankOption !== true && !params?.value)
        options.push('')

    const passprops = useMemo(() => {
        const x = {...params}
        delete x.error
        delete x.custom
        delete x.setValue
        return x
    }, [params]);

    return <Autocomplete
        autoHighlight
        freeSolo={Boolean(custom)}
        autoSelect={!Boolean(custom)}
        getOptionLabel={(val) => {
            if (typeof(val) === 'undefined' || val === null) return ''
            const opt = options.find(o => (typeof o.code !== 'undefined' && (o.code === val?.code || o.code === val)) || o === val)
            let value = opt?.label || opt || val
            return value?.toString()
        }}
        isOptionEqualToValue={(opt, val) => {
            return opt?.code === val?.code || opt?.code === val || opt === val
        }}
        renderOption={(innerProps, val) => (
            <Box component="li" {...innerProps}>
                {val?.label || val}
            </Box>
        )}
        disableListWrap
        renderInput={tparams => (
            <TextField
                {...tparams}
                label={label}
                InputLabelProps={{shrink: true}}
                inputProps={{...tparams.inputProps, autoComplete: 'off',}}
                error={params?.error}
            />
        )}
        onInputChange={(e, val) => {
            if (val === null) val = ''
            if (typeof val === 'object') {
                return setValue(val.code)
            }
            const opt = options.find(o => o?.label === val);
            return setValue(opt ? opt.code : val)
        }}
        {...passprops}
        sx={{margin: '2px', mt:2, ...sx}}
    />
}
