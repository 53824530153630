import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import {forwardRef} from "react";

const PlaceholderCard = forwardRef(function PlaceholderCard(props, ref) {
    const {error, resetErrorBoundary, title = 'Loading...', iconColor = '#888'} = props;
    const {icon = <CardTravelOutlinedIcon/>} = props;
    const theme = useTheme();

    return (
        <Box ref={ref}
             backgroundColor='dea.jetBlack'
             borderRadius='8px'
             boxShadow={`0px 2px 10px ${theme.palette.dea.chipFill}`}
             height='100%'
             width='100%'
        >
            <Stack gap='12px' padding='0 16px 16px' height='100%'>
                <Box component='header' display='flex' justifyContent='space-between' >
                    <Box display='flex' gap='12px'>
                        <Box width='60px'
                             height='60px'
                             flexShrink={0}
                             display='flex'
                             justifyContent='center'
                             alignItems='center'
                             backgroundColor={iconColor}
                             borderRadius='14px'
                             sx={{transform: 'translateY(-6px)'}}
                        >{icon}</Box>
                        <Typography component='span' paddingTop='12px' fontWeight='bold'>{title}</Typography>
                    </Box>
                    <Stack gap='12px' alignItems='flex-end' paddingTop='12px'>
                        <Placeholder sx={{ width: '120px', height: '12px' }} />
                        <Placeholder sx={{ width: '60px' }} />
                    </Stack>
                </Box>

                <Stack paddingTop='12px' borderTop={`1px solid ${theme.palette.dividerInverted}`} height='100%' gap='12px'>
                    {error
                        ? <ErrorContent resetErrorBoundary={resetErrorBoundary} />
                        : [1, 2, 3, 4].map(n => <Box key={n} display='flex' justifyContent='space-between'>
                            <Placeholder sx={{ width: '120px' }} />
                            <Placeholder sx={{ width: '120px' }} />
                        </Box>)}
                </Stack>

                <Box paddingTop='12px' borderTop={`1px solid ${theme.palette.dividerInverted}`}>
                    <Placeholder sx={{ width: '120px' }} />
                </Box>
            </Stack>
        </Box>
    )
})
export default PlaceholderCard;

export function Placeholder(props) {
    const theme = useTheme();

    return <Box sx={{
        '@keyframes Animation': {
            '0%': { backgroundPosition: '0%' },
            '50%': { backgroundPosition: '100%' },
            '100%': { backgroundPosition: '0%' },
        },
        animation: 'Animation 2s ease infinite',
        background: `linear-gradient(90deg, ${theme.palette.dea.fieldOutline} 0%, ${theme.palette.dea.stealthGrey} 100%)`,
        width: '100%',
        height: '1rem',
        backgroundSize: '200%',
        borderRadius: '20px',

        ...props.sx
    }} />
}

function ErrorContent({ resetErrorBoundary }) {
    const onClick = () => {
        if (typeof resetErrorBoundary === 'function') {
            resetErrorBoundary()
        } else {
            console.warn('resetErrorBoundary is not a function', typeof resetErrorBoundary, resetErrorBoundary)
        }
    }
    const theme = useTheme();
    return <>
        <Box display='flex' justifyContent='space-between'>
            <Placeholder sx={{ width: '120px' }} />
            <Placeholder sx={{ width: '120px' }} />
        </Box>
        <Button onClick={onClick}
            size='small'
            color='error'
            style={{ fontSize: '10px', color: theme.palette.dea.jetBlack, fontWeight: '700', }}
            endIcon={<ErrorOutlinedIcon />}
        >
            Something went wrong, please retry
        </Button>
        <Box display='flex' justifyContent='space-between'>
            <Placeholder sx={{ width: '120px' }} />
            <Placeholder sx={{ width: '120px' }} />
        </Box>
    </>
}
