import ConnectingAirportsOutlinedIcon from '@mui/icons-material/ConnectingAirportsOutlined';
import {Box, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';

import "leaflet/dist/leaflet.css";
import marker from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import '@elfalem/leaflet-curve'

const iconConfig = {iconUrl: marker, iconSize: [25, 41], iconAnchor: [12.5, 41], popupAnchor: [0, -41], }
const tileConfig = {url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png", minZoom: 3, maxZoom: 17, attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'}

function makeQuadraticCurve(from, to) {
    return [
        "M", from,
        "Q", [(from[0] + to[0]) / 1.9, (from[1] + to[1]) / 2],
        to,
    ];
}

export default function FlightsAirports({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);
    const mapDiv = useRef(null);
    const mapRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    const {airports = {}, flight_paths = {}, metadata = {}} = data || {}
    
    useEffect(() => {
        if (!data || mapRef.current) return;

        mapRef.current = L.map(mapDiv.current, {attributionControl: false}).setView([42.416775,-3.70379], 4);
        const icon = L.icon(iconConfig);

        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", tileConfig).addTo(mapRef.current);

        L.geoJSON(airports?.features, {
            pointToLayer: function (feature, latlng) {
                return L.marker(latlng, {icon, title: `${feature.properties.name} (${latlng.lat}, ${latlng.lng})`});
            },
        }).addTo(mapRef.current);

        flight_paths?.features.forEach(({properties, geometry}) => {
            const [from, to] = geometry.coordinates.map(([lng, lat]) => [lat, lng]); // Leaflet uses [lat,lng] format instead of [lng,lat]
            L.curve(makeQuadraticCurve(from, to))
                .addTo(mapRef.current)
                .bindPopup(`Plane: ${properties.plane} From ${from.join(', ')} to ${to.join(', ')}`);
        })
    }, [data]);

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Recent Flight'
            icon={<ConnectingAirportsOutlinedIcon/>}
            iconColor={theme.palette.tiles.blue}
            metadata={metadata}
        >
            <Box ref={mapDiv} sx={{width: '100%', height: '100%'}}/>
        </CardContainer>
    )
}