import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import {Box, Divider, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function WeatherReport({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {locations = [], current_avg_temp, text, metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Weather Report'
            icon={<DeviceThermostatOutlinedIcon/>}
            iconColor={theme.palette.tiles.teal}
            headerValues={[{heading: 'Current avg. temp', value: ['',undefined,null].includes(current_avg_temp) ? 'N/A' : `${current_avg_temp}°C`}]}
            metadata={metadata}
        >
            <Stack gap='6px' sx={{fontSize: '12px'}}>
                <Box>{text}</Box>

                <Divider/>

                <Box component='table' aria-label="Table of locations and temperature" width='100%'>
                    <Box component='thead' fontSize='10px'>
                        <Box component='tr' color={theme.palette.gray["500"]} sx={{textWrap:'nowrap'}}>
                            <Box padding='0 0 3px 0' textAlign='left' component='th'>LOCATION</Box>
                            <Box padding='0 3px 3px' textAlign='left' component='th'>WEATHER REPORT</Box>
                            <Box padding='0 0 3px 0' textAlign='right' component='th'>MAX TEMP.</Box>
                        </Box>
                    </Box>
                    <Box component='tbody'>
                        {locations?.map((location) => (
                            <Box component='tr' key={location?.location} fontWeight='medium'>
                                <Box padding='3px 0' component='td' sx={{textWrap: 'nowrap'}}>{location?.location}</Box>
                                <Box padding='3px' component='td'>{location?.weather?.substring(0, 30)}...</Box>
                                <Box padding='3px 0' component='td' textAlign='center'>{location?.max_temp}°C</Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Stack>
        </CardContainer>
    )
}