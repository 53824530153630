/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

// converts eg. int(85) to "1h25m"
export function minutesToTime(m) {
    if (!m) return '-'
    return `${Math.floor(m / 60)}h${m % 60}m`
}

// converts eg. "1h25m" to int(85)
export function timeToMinutes(t) {
    if (!t) return 0
    t = t.trim().replace('m', '').split('h')
    if (t.length === 1) return parseInt(t[0])  // assume minutes only
    return (parseInt(t[0]) * 60) + parseInt(t[1])
}

export function basicMonthsList() {
    const today = dayjs()
    const _months = [
        (today.$y * 100) + (today.$M + 1)  // current month
    ]
    for(let i = 0; i<=4; i++) {
        let m = today.$M - i
        let y = m < 1 ? today.$y - 1 : today.$y
        m += m < 1 ? 12 : 0
        _months.push((y * 100) + m)
    }
    _months.sort()
    return _months
}

export function yyyymmToday() {
    const today = dayjs()
    return (today.$y * 100) + (today.$M + 1)
}
export const dayJsToIsoString = v => v ? v.utc().format() : null;
export const isoStringToDayJs = v => v ? dayjs.utc(v) : null;
// In JS Dates, the month is 0 indexed, We want the month+1, day 0, to get the last day of the prev month
export const daysInMonth = yyyymm => new Date(Date.UTC(Math.floor(yyyymm / 100), yyyymm % 100, 0)).getUTCDate();

export const yyyymmddToday = () => dayJsToYyyymmdd(dayjs());
export const dayJsToYyyymmdd = date => (date.$y * 10000) + ((date.$M+1) * 100) + date.$D;
export const dayJsToYyyymm = date => (date.$y * 100) + ((date.$M+1));
export const firstDayOfMonth = yyyymm => (yyyymm*100) + 1;
export const lastDayOfMonth = yyyymm => (yyyymm*100) + (daysInMonth(yyyymm) + 1);

export function anniversaries_from_first_day(date) {
    date = dayjs(date)
    date.setFullYear(dayjs().year)
    if (date > dayjs())
        date.sub(1, 'year')
    return {
        prev_anni: date.sub(1, 'year'), // the anniversay before that
        last_anni: date, // the anniversary just gone
        next_anni: date.add(1, 'year')  // the next anniversary
    }
}

export function dayOfWeek(yyyymmdd) {
    const s = String(yyyymmdd)
    const d = new Date([s.slice(0, 4), s.slice(4, 6), s.slice(6, 8)].join('-'))
    const dayNames = ["Sun", "Mon", "Tue", 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return dayNames[d.getDay()]
}

export function yyyymmToReadable(yyyymm) {
    const yyyy = Math.floor(yyyymm / 100)
    const mm = (yyyymm % 100)
    const strm = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return `${strm[mm]} ${yyyy}`
}

export function yyyymmToReadableFullMonth(yyyymm) {
    const mm = (yyyymm % 100)
    const strm = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return `${strm[mm]}`
}

export function yyyymmToReadableYear(yyyymm) {
    const yyyy = Math.floor(yyyymm / 100)
    return `${yyyy}`
}

export function yyyymmddToReadable(yyyymmdd) {
    const dow = dayOfWeek(yyyymmdd)
    const day = yyyymmdd % 100
    const dm10 = day % 10
    const pp = day >= 4 && day < 20 ? 'th' : dm10 === 1 ? 'st' : dm10 === 2 ? 'nd' : dm10 === 3 ? 'rd' : 'th'
    return dow + ' ' + day + pp
}

export function yyyymmddToFullReadable(yyyymmdd) {
    const year = Math.floor(yyyymmdd / 10000)
    const dow = dayOfWeek(yyyymmdd)
    const day = yyyymmdd % 100
    const dm10 = day % 10
    const mm = (Math.floor(yyyymmdd / 100) % 100)
    const strm = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const pp = day >= 4 && day < 20 ? 'th' : dm10 === 1 ? 'st' : dm10 === 2 ? 'nd' : dm10 === 3 ? 'rd' : 'th'
    return `${dow} ${day}${pp} ${strm[mm]} ${year}`
}

export function yyyymmddToDate(yyyymmdd) {
    const s = String(yyyymmdd)
    return new Date([s.slice(0, 4), s.slice(4, 6), s.slice(6, 8)].join('-'))
}

export function yyyymmddToDayJs(yyyymmdd) {
    const s = String(yyyymmdd)
    return dayjs([s.slice(0, 4), s.slice(4, 6), s.slice(6, 8)].join('-'))
}

export function yyyymmToDayJs(yyyymm) {
    return yyyymmddToDayJs((yyyymm*100) + 1)
}

export function prevMonthYyyymm(yyyymm) {
    let n = yyyymm - 1
    if (n % 100 === 0)
        n += 12 - 100
    return n
}

export function nextMonthYyyymm(yyyymm) {
    let n = yyyymm + 1
    if (n % 100 === 13)
        n += 100 - 12
    return n
}
/**
 * Converts time to Zulu format
 * @param {String} time 
 * @returns {String} time in Zulu format
 */
export function convertToZulu(time) {
    return dayjs(time, "HH:mm:ss").utc().format("HHmm [Z]");
}