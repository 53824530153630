/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {dayJsToIsoString, isoStringToDayJs} from "../utils/ymd";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export default function DateTimePicker(params) {
    const {error, helperText, required, value, setValue} = params;
    const mparams = {...params}
    delete mparams.error;
    delete mparams.helperText;
    delete mparams.required;
    delete mparams.value;
    delete mparams.onChange;

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="UTC">
        <MuiDateTimePicker
            onChange={v => setValue(dayJsToIsoString(v))}
            value={isoStringToDayJs(value)}
            timezone={"UTC"}
            slotProps={{
                textField: {
                    [error?'error':null]: true,
                    [required?'required':null]: true,
                    helperText,
                },
            }}
            format="YYYY-MM-DD HH:mm"
            {...mparams}
        />
    </LocalizationProvider>
}
