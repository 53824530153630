import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function StaffHandbookAndCompanyVision({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {policies = [], links = {}, metadata = {} } = data || {};

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title='Staff Handbook and Company Vision'
            icon={<BookOutlinedIcon/>}
            iconColor={theme.palette.tiles.red}
            footerLinks={[
                { text: 'Company Processes (AS9001)', href: links?.staff_handbook?.uri },
                { text: 'Travel Policy', href: links?.travel_policy?.uri },
            ]}
            metadata={metadata}
        >

            <Stack gap='12px' >
                <Stack gap='6px' paddingBottom='12px' borderBottom={`1px solid ${theme.palette.dividerInverted}`}>
                    <Typography fontSize='12px' textAlign='center'>On the Staff Handbook website you will find a collections of rules and regulations all employees need to know and follow.</Typography>
                    <Box display='flex' justifyContent='center'>
                        <Button
                            size='small'
                            disableElevation
                            color='primary'
                            style={{ fontSize: '10px', color: theme.palette.dea.jetBlack, fontWeight: '700' }}
                            endIcon={<ChevronRightOutlinedIcon />}
                            href={links?.staff_handbook?.uri} >
                            Visit Staff Handbook and Vision
                        </Button>
                    </Box>
                </Stack>
                <Stack gap='6px' >
                    {policies?.map(({ name, uri }) => (
                        <Box display='flex' justifyContent='space-between' gap='12px' key={name}>
                            <Typography fontSize='12px'>{name}</Typography>
                            <Typography fontSize='12px' color='inherit' fontWeight='500' sx={{
                                textDecoration: 'none',
                                '&:hover': { color: 'dea.turquoise' }
                            }} href={uri} component='a'>
                                {name} link
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </CardContainer >
    )
}