import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

export default function renderBiIcon(name) {
    switch (name) {
        case 'occurrence':
            return <FlightOutlinedIcon/>

        case 'operations':
            return <SupportOutlinedIcon/>

        case 'flight_hours':
            return <QueryBuilderOutlinedIcon/>

        default:
            return <ImageNotSupportedOutlinedIcon/>
    }
};
