import LockIcon from '@mui/icons-material/Lock'; 
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";

import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import renderBiIcon from './renderBiIcon';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function BusinessIntelligenceDashboards({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {setLoading, setError, onDone: setData})
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {dashboards=[], featured=[], metadata={}} = data || {};

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Business Intelligence Dashboards"
            icon={<PsychologyOutlinedIcon/>}
            iconColor={theme.palette.tiles.blue}
            headerValues={[{ heading: 'Total dashboards', value: dashboards.length }]}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gridTemplateRows='1fr' gap='6px'>
                    {featured?.map((dashboard) => (
                        <Stack
                            key={dashboard?.name}
                            gap='6px' alignItems='center'
                            padding='8px 12px 0' textAlign='center'
                            color='currentColor'
                            sx={{
                                textDecoration: 'none',
                                opacity: dashboard?.has_access ? 1 : 0.5,
                                '&:hover': {color: dashboard?.has_access ? theme.palette.dea.turquoise : null}
                            }}
                            component='a'
                            target='_blank'
                            rel='noopener noreferrer'
                            href={dashboard?.has_access ? dashboard?.url : null}
                        >
                            <Box width='50px'
                                 height='50px'
                                 borderRadius='1000px'
                                 display='flex'
                                 justifyContent='center'
                                 alignItems='center'
                                 color='#000'
                                 position='relative'
                                 sx={{ backgroundColor: 'gray.100' }}
                            >
                                {renderBiIcon(dashboard?.icon)}

                                {(!dashboard?.has_access) && <Box position='absolute' top='-3px' right='-3px' backgroundColor='dea.stealthGrey' borderRadius='100px' border="2.5px solid" borderColor='dea.arcticSilver' color='dea.arcticSilver' width='24px' height='24px' display='flex' justifyContent='center' alignItems='center'>
                                    <LockIcon sx={{width:'16px'}}/>
                                </Box>}
                            </Box>
                            <Typography fontSize='12px'>
                                {dashboard?.name}
                            </Typography>
                        </Stack>
                    ))}
                </Box>
                <Stack gap='6px' fontSize='12px'>
                    {dashboards?.map((dashboard) => (
                        <Box display='flex' justifyContent='space-between' gap='12px' key={dashboard?.name}>
                            <Typography fontSize='inherit' fontWeight='500'>{dashboard?.name}</Typography>
                            {
                                dashboard.has_access ? (
                                    <Link
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={dashboard?.url}
                                        display='flex'
                                        alignItems='center'
                                        sx={{textDecoration: 'none', ':hover': {color: 'dea.turquoise'}}}
                                        gap='2px'>
                                        <Typography component='span' fontSize='10px'>View</Typography>
                                        <ArrowForwardOutlinedIcon sx={{width: 16, height: 16}} />
                                    </Link>
                                ) : (
                                    <Box display='flex' alignItems='center' gap='2px'>
                                        <Typography component='span' fontSize='10px'>N/A</Typography>
                                        <LockOutlinedIcon sx={{width: 16, height: 16}} />
                                    </Box>
                                )
                            }
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </CardContainer>
    )
}