import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import { Box, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import {useEffect, useRef, useState} from 'react';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

const displayDeploymentType = {
    ro: "Rostered Off",
    wfh: "Working From Home",
    deployed: "Deployed",
    notdeployed: "Non-contract work",
    training: "Training (as instructor)",
    leave: "Leave / Holiday"
}

const pluraliseForCount = (count, word) => (count > 1) ? `${word}s` : word;

export default function Schengen({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {schengen_information={}, links={}, metadata={}} = data || {};
    const {breakdown_by_type={}} = schengen_information;
    const {days_spent_total=0, days_allowed_total=0} = schengen_information;
    const deploymentTypes = Object.keys(breakdown_by_type);

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            error={error}
            data={data}
            title="Schengen"
            icon={<WhereToVoteOutlinedIcon/>}
            iconColor={theme.palette.tiles.teal}
            headerValues={[{ heading: 'Schengen days', value: `${days_spent_total}/${days_allowed_total}` }]}
            footerLinks={[{ text: 'See more', href: links?.view_timesheets?.uri }]}
            metadata={metadata}
        >
            <Stack gap='6px'>
                <Typography fontSize='12px' fontWeight='bold'>
                    Latest Schengen locations
                </Typography>
                {deploymentTypes.map(deploymentType => (
                    <Box display='flex' justifyContent='space-between' gap='12px' key={deploymentType}>
                        <Typography fontSize='12px'>
                            {displayDeploymentType[deploymentType]}
                        </Typography>
                        <Typography fontSize='12px' fontWeight='500'>
                            {breakdown_by_type[deploymentType]?.countries.map(country => `${country.country_name}`).join(', ')}
                            &nbsp;
                            (
                                {breakdown_by_type[deploymentType].days_spent}
                                &nbsp;
                                {pluraliseForCount(breakdown_by_type[deploymentType].days_spent, 'day')}
                            )
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </CardContainer>
    )
}