/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {HelmetProvider} from 'react-helmet-async';
import {HashRouter} from 'react-router-dom'
import {LicenseInfo} from "@mui/x-license-pro";

LicenseInfo.setLicenseKey('473f7a35c4c96267b7e044104445562dTz04NjE4OSxFPTE3NDM3ODI0MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HashRouter>
            <HelmetProvider>
                <App/>
            </HelmetProvider>
        </HashRouter>
    </React.StrictMode>
);
