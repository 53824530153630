/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
export function getServer() {
    // NOTE that this is .hostname which CUTS OFF the port, so :3000 will disappear.
    return `https://${window.location.hostname}${window.location.pathname}`.replace(/\/$/,'')
}

export const ucFirst = s => s.charAt(0).toUpperCase() + s.slice(1);
