/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {useState, useCallback, useEffect} from "react"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import {BASEPATH} from "./AppConfigStore";

export const useServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = useState(null)
    const [updateReady, setUpdateReady] = useState(false)

    // called when a service worker
    // updates. this function is a callback
    // to the actual service worker
    // registration onUpdate.
    const onSWUpdate = useCallback(registration => {
        console.log('update received, an update is available')
        setUpdateReady(true)
        setWaitingWorker(registration.waiting)
    }, [])

    // simply put, this tells the service
    // worker to skip the waiting phase and then reloads the page
    const reloadPage = useCallback(() => {
        if (waitingWorker)
            waitingWorker.postMessage({type: "SKIP_WAITING"})
        setUpdateReady(false)
        window.location.reload()
    }, [waitingWorker])

    // register the service worker
    useEffect(() => {
        serviceWorkerRegistration.register({
            swOpts: {scope: BASEPATH},
            onUpdate: onSWUpdate,
        })
    }, [onSWUpdate])
    return {updateReady, waitingWorker, reloadPage}
}
