/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
/**
 * Example usage:
 *
 * import create from "zustand";
 * import Acl from './panel/Acl'
 * import Admins from './panel/Admins'
 * import Query from './panel/Query'
 *
 * export const DEVMODE = window.location.hostname.match('localhost')
 * export const BASEPATH = DEVMODE ? '/app/' : '/my_custom_app/'
 *
 * export const useAppConfigStore = create(() => ({
 *     name: 'My App',
 *     helplink: 'https://sites.google.com/dea.aero/my_app',
 *     pages: [
           ['HomePage',     '/',                <HomePage/>],
 *         ['ACL',          '/acl',             <Acl/>],
           ['Admins',       '/admins',          <Admins/>],
 *         ['Query',        '/query',          <Query/>],
 *     ],
 *     preload: [],
 * }))
 */


// @uncommon
import create from "zustand";
import Acl from './panel/Acl'
import Admins from './panel/Admins'
import Query from "./panel/Query";
import Home from "./panel/Home";
import AnnouncementsPage from "./panel/AnnouncementsPage";

import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

export const DEVMODE = window.location.hostname.match('localhost')
export const BASEPATH = DEVMODE ? '/app/' : '/dashboard/'

export const useAppConfigStore = create(() => {
    return {
        name: 'FlightDeck - Dashboard',
        helplink: null,
        pages: [
            ['FlightDeck', '/', <Home />, { icon: <SpaceDashboardOutlinedIcon /> }],
            ['Announcements', '/announcements', <AnnouncementsPage />, {icon: <CampaignOutlinedIcon /> }],
            ['ACL', '/acl', <Acl />, { icon: <DashboardCustomizeOutlinedIcon /> }],
            ['Admins', '/admins', <Admins />, { icon: <AdminPanelSettingsOutlinedIcon /> }],
            ['Query', '/query', <Query />, { icon: <QueryStatsOutlinedIcon /> }],
        ],
        permissions: {
            'View Dashboard': [
                'GET /api/login',
                'GET /api/logout',
                'GET /api/business_intelligence',
                'GET /api/tiles',
                'GET /api/aircraft',
                'GET /api/planning_meetings',
                'GET /api/assets_licenses',
                'GET /api/apps',
                'GET /api/tasks',
                'GET /api/first_aid',
                'GET /api/training',
                'GET /api_company_cards',
                'GET /api/blog',
                'GET /api/business_improvement_suggestions',
                'GET /api/wellbeing',
                'GET /api/projects',
                'GET /api/vacancies',
                'GET /api/contracts',
                'GET /api/user_details',
                'GET /api/internal_policies',
                'GET /api/company_information',
                'GET #/'
            ],
            'Pilot View': [
                'GET /api/pilot_information',
                'GET /api/timesheet',
                'GET /api/schengen'
            ]
        }
    }
})
