import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import {Box, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function AircraftMaintenance({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 10000, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {maintenances = [], total_maintenances = '0', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Aircraft Maintenance'
            icon={<BuildOutlinedIcon/>}
            iconColor={theme.palette.tiles.red}
            showSeeMore={false}
            headerValues={[{heading: 'Total under maintenance', value: total_maintenances}]}
            metadata={metadata}
        >
            <Box component='table' aria-label="Table of aircraft under maintenance" width='100%' fontSize='12px' textAlign='center'>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color={theme.palette.gray["500"]} sx={{textWrap: 'nowrap'}}>
                        <Box component='th' padding='0 0 3px 0' textAlign='left'>AIRCRAFT</Box>
                        <Box component='th' padding='0 3px 3px'>LOCATION</Box>
                        <Box component='th' padding='0 3px 3px'>ORIGINAL DATE</Box>
                        <Box component='th' padding='0 0 3px 0'>NEW DATE</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {maintenances?.map((maintenance) => (
                        <Box component='tr' key={maintenance?.latest_estimate + maintenance?.original_output_date} fontWeight='medium'>
                            <Box component='td' padding='3px 0' textAlign='left'>{maintenance?.aircraft}</Box>
                            <Box component='td' padding='3px'>{maintenance?.location}</Box>
                            <Box component='td' padding='3px'>{maintenance?.original_output_date}</Box>
                            <Box component='td' padding='3px 0'>{maintenance?.latest_estimate}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}