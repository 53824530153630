import {Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, useTheme} from "@mui/material";
import Announcement from "../component/dashboard/Announcement";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import {useCallback, useState} from "react";
import {useApiCallback} from "../utils/Api";
import DateTimePicker from "../component/DateTimePicker";

export default function AnnouncementsPage() {
    const theme = useTheme();
    const [edit, setEdit] = useState(null);

    return (
        <Stack maxWidth='1280px' margin={{xs: '1.5rem 0', xl: 'auto'}} gap='2.5rem' style={{color: theme.palette.dea.arcticSilver, '& label': {color: 'currentColor'}}} minHeight='70vh'>
            <Stack alignItems='end'>
                {!edit && <Button onClick={() => setEdit({})} startIcon={<CampaignOutlinedIcon/>}>New Announcement</Button>}
            </Stack>
            {edit && <AddAnnouncement setEdit={setEdit} edit={edit}/>}
            <Announcement setEdit={setEdit} type="all" edit={edit} />
        </Stack>
    )
}

function AddAnnouncement({edit: {announcement_id, end, message, start, type}, setEdit}) {
    const [form, setForm] = useState({message, start, end, type, announcement_id});
    
    const [loading, setLoading] = useState(false);
    const run = useApiCallback('POST', `/api/announcements`, {setLoading, onDone: () => setEdit(null), data: form})
    const runClick = useCallback(() => run(form), [form, run])

    const deleteAnnouncement = useApiCallback('DELETE', `/api/announcements/${announcement_id}`, {setLoading, onDone: () => setEdit(null)})
    
    return (
        <Stack gap='1rem'>
            <Typography component='h2' variant="h5">{announcement_id ? 'Edit' : 'New'} Announcement</Typography>

            <TextField required fullWidth multiline label="Message" value={form.message}
                    onChange={(e) => setForm({...form, message: e.currentTarget.value})}/>
                
            <Stack direction={{md: 'row'}} gap='1rem' sx={{'&>*': {width: '100%'}}}>
                <DateTimePicker
                    required='required'
                    label="Display from (inclusive)"
                    value={form.start}
                    setValue={v => setForm({...form, start: v.split('+')[0]})}
                />
                <DateTimePicker
                    required='required'
                    label="Display until (inclusive)"
                    value={form.end}
                    setValue={v => setForm({...form, end: v.split('+')[0]})}
                />
                <FormControl>
                    <InputLabel size='small' id="alert-type" required>Type</InputLabel>
                    <Select required
                        labelId="alert-type"
                        value={form.type || 'Info'}
                        label="Type"
                        onChange={(e) => setForm({...form, type: e.target.value})}
                    >
                        {[['Error', 'Important notice (red)'], ['Info', 'Informational notice (blue)']].map(opt => <MenuItem value={opt[0].toLowerCase()} key={opt[0]}>{opt[1]}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box justifyContent='end' display='flex'>
                    {announcement_id && <Button onClick={() => deleteAnnouncement()} color="error" startIcon={loading && <CircularProgress color="inherit" size='1rem' />}>
                        Delete
                    </Button>}
                    <Button onClick={runClick} startIcon={loading && <CircularProgress color="inherit" size='1rem' />}>
                        Publish
                    </Button>
                </Box>
            </Stack>
        </Stack>
    )
}