import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {convertToZulu} from '../../utils/ymd';

export default function LookOvertheShoulder({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {updates = [], last_update = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Look Over the Shoulder'
            icon={<InputOutlinedIcon sx={{transform: 'rotate(180deg)'}}/>}
            iconColor={theme.palette.tiles.blue}
            headerValues={[{heading: 'Last update', value: convertToZulu(last_update)}]}
            metadata={metadata}
        >
            {updates.length && <Stack gap='6px' fontSize='12px' textAlign='center'>
                <Box fontWeight='bold'>What was achieved Since the last SAB? Over the weekend?</Box>
                {updates.map((update) => <Box key={update.title}><b>{update.title}</b> - {update.detail}</Box>)}
            </Stack>}
        </CardContainer>
    )
}