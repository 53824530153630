import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import { useApiCallback } from '../../utils/Api';
import { useEffect, useRef, useState } from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function DeliveryMeetings({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])
   
    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const { meetings=[], metadata={} } = data || {};

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Delivery Meetings"
            icon={<GroupsOutlinedIcon/>}
            iconColor={theme.palette.tiles.lighterBlue}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Typography fontSize='12px'>
                    Once a month the delivery team meets to agree direction.
                </Typography>
                <Divider/>
                <Stack gap='6px'>
                    {meetings.map(({ name, uri }) => (
                        <Box display='flex' justifyContent='space-between' gap='12px' key={name}>
                            <Typography fontSize='12px'>{name}</Typography>
                            <Typography fontSize='12px' color='inherit' fontWeight='500' sx={{
                                textDecoration: 'none',
                                '&:hover': { color: 'dea.turquoise' }
                            }} target='_blank' href={uri} component='a'>View</Typography>
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </CardContainer>
    )
}