import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import {Box, Divider, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {convertToZulu} from '../../utils/ymd';

export default function LookAhead({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {isr_updates = [], ts_updates = [], last_update = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Look Ahead'
            icon={<InputOutlinedIcon/>}
            iconColor={theme.palette.tiles.darkBlue}
            headerValues={[{heading: 'Last update', value: convertToZulu(last_update)}]}
            metadata={metadata}
        >
            <Stack sx={{fontSize: '12px'}} gap='12px' textAlign='center'>
                {isr_updates.length && <Stack gap='6px'>
                    <Box fontWeight='bold'>ISR A/C Information:</Box>
                    {isr_updates.map((update) => (
                        <Box key={update.title}><b>{update.title}</b> - {update.detail}</Box>
                    ))}
                </Stack>}
                <Divider/>
                {ts_updates.length && <Stack gap='6px'>
                    <Box fontWeight='bold'>Upcoming TS/Pilot Training:</Box>
                    {ts_updates.map((update) => (
                        <Box key={update.title}><b>{update.title}</b> | {update.detail}</Box>
                    ))}
                </Stack>}
            </Stack>
        </CardContainer>
    )
}