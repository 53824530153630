import PaymentIcon from '@mui/icons-material/Payment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";

export default function RequestCard() {
    const theme = useTheme();

    return (
        <Box maxWidth='400px' color='dea.arcticSilver'>
            <CardContainer
                title='Company card'
                icon={<CreditScoreIcon/>}
                iconColor={theme.palette.tiles.orange}
            >
                <Stack gap='12px'>
                    <Typography fontSize='12px' textAlign='center' >You don’t have a company card.</Typography>
                    <Stack alignItems='center'>
                        <Button
                            href='mailto:accountsenquiries@dea.aero?subject=Request+company+card'
                            size='small'
                            disableElevation
                            color='primary'
                            style={{fontSize: '10px', fontWeight: '600'}}
                            startIcon={<PaymentIcon />}>
                            Request company card
                        </Button>
                    </Stack>
                </Stack>
            </CardContainer>
        </Box>
    )
}