import { Box, Stack, useTheme } from "@mui/material";

const linkArrays = [
    [
        { link: 'https://sites.google.com/dea.aero/managementsystems/iso/health-safety/mental-health-awareness-support', text: 'Mental Health Awareness & Support' },
        { link: 'https://sites.google.com/dea.aero/managementsystems/iso/health-safety/first-aid-tipsdemos', text: 'First Aid' },
        { link: 'https://dea.centrik.net/', text: 'Centrik Reporting' },
    ],
    [
        { link: 'https://sites.google.com/dea.aero/it-support/home', text: 'IT Support' },
        { link: 'https://sites.google.com/dea.aero/gws-training/home', text: 'Google Training' },
        { link: 'https://sites.google.com/dea.aero/gws-training/secure-ways-of-working', text: 'Secure Ways of Working' },
    ],
    [
        { link: 'https://sites.google.com/dea.aero/agsr/home', text: 'AGSR' },
        { link: 'https://sites.google.com/dea.aero/dea-eng/home', text: 'Engineering' },
        { link: 'https://sites.google.com/dea.aero/facilities/home', text: 'Facilities' },
        { link: 'https://sites.google.com/dea.aero/finance', text: 'Finance' },
        { link: 'https://sites.google.com/dea.aero/flight-ops/home', text: 'Flight Ops' },
        { link: 'https://sites.google.com/dea.aero/groundoperations/home', text: 'Ground Ops' },
        { link: 'https://sites.google.com/dea.aero/ia-intranet/home', text: 'Intel Analysts' },
        { link: 'https://sites.google.com/dea.aero/managementsystems/home', text: 'Management Systems' },
        { link: 'https://sites.google.com/dea.aero/human-resources/home', text: 'People' },
        { link: 'https://sites.google.com/dea.aero/pilot-training/home', text: 'Pilot Training' },
        { link: 'https://sites.google.com/dea.aero/pmo/home', text: 'PMO' },
        { link: 'https://sites.google.com/dea.aero/systems/home', text: 'Systems' },
        { link: 'https://sites.google.com/dea.aero/tsportal?pli=1', text: 'Task Specialists' },
        { link: 'https://sites.google.com/dea.aero/deatravel/home', text: 'Travel' },
    ]
]

export default function SubpagesMenu({ onClick }) {
    const theme = useTheme();
    return <>
        <Stack gap='8px' sx={{
            '& .MuiListItem-root': {
                backgroundColor: `${theme.palette.dea.stealthGrey}`,
                color: theme.palette.dea.invertedJetBlack,
                '&:hover': {
                    backgroundColor: theme.palette.dea.chipFill,
                }
            },
        }}>
            {linkArrays.map((lists, i) => (
                <Stack sx={{ listStyle: 'none', padding: 0 }} gap='1px' borderRadius='8px' overflow='hidden'>
                    {lists.map((list, j) => (
                        <Stack key={i + j} component='a'
                            backgroundColor='dea.stealthGrey'
                            color='dea.invertedJetBlack'
                            sx={{ textDecoration: 'none', padding: '8px 16px', '&:hover': { backgroundColor: 'dea.chipFill' } }}
                            target='_blank'
                            rel='noopener noreferrer'
                            href={list.link}
                        >
                            {list.text}
                        </Stack>
                    ))}
                </Stack>
            ))}
        </Stack >
    </>
}