/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */

/* @uncommon - @FIXME needs removing for integration to common-code */

import {useToastStore} from "../Toast"
import {getServer} from './server'
import {useCallback} from "react";
import {DEVMODE} from "../AppConfigStore";
import {create} from 'zustand'

const server = getServer()

export const useApiStore = create((set) => ({
    asUser: null,
    setAsUser: (asUser) => set({asUser}),
}))

export function useApiCallback(method, path, {data, setLoading, onDone, setError, ignoreErrors, returnAll}) {
    const errorToast = useToastStore((state) => state.showError)
    const {asUser} = useApiStore()

    return useCallback((altdata=null) => {
        let txdata = data
        if (altdata !== null) {
            txdata = altdata
        }
        if (!path) return () => {}
        setLoading && setLoading(true)
        const controller = new AbortController()
        const opts = {
            method: method,
            signal: controller.signal,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Use-Static-Data': localStorage.getItem('Use-Static-Data') || false,
            },
            mode: 'cors',
        }

        if (asUser) {
            console.log('X-As-User', asUser)
            opts.headers['X-As-User'] = asUser;
        } else {
            console.log('X-As-User', 'NONE')
        }

        if (DEVMODE) {
            opts.credentials = 'include'
        }

        if (txdata && opts.method !== 'GET') {
            opts.body = JSON.stringify(txdata)
        }

        let url = path.match(/^https?:/) ? path : server + path
        if (altdata && opts.method === 'GET') {
            url += '?' + new URLSearchParams(altdata).toString()
        }
        fetch(url, opts)
            .then((response) => {
                setLoading && setLoading(false)

                const contenttype = response.headers.get('content-type');
                if (contenttype === 'application/zip') {
                    response.blob().then(blob => {
                        const file = window.URL.createObjectURL(blob);
                        window.location.assign(file);
                    }, err => {
                        console.error('blob file error', err)
                    })
                    onDone(null)
                    return
                }

                response.json().then(data => {
                    if (!response.ok) {
                        data = JSON.stringify((data.hasOwnProperty('data')) ? data.data : data)
                        if (!ignoreErrors) {
                            errorToast(data)
                            setError && setError(true)
                        }
                        return
                    }
                    if (onDone) {
                        setError && setError(false)
                        if (returnAll)
                            onDone(data)
                        else
                            onDone(data.data, txdata)
                    }
                }, (e) => {
                    setLoading && setLoading(false)
                    setError && setError(true)
                    console.error(e)
                })
            }, (e) => {
                setLoading && setLoading(false)
                if (!String(e).match('signal is aborted without reason')) // seriously GTFO
                    console.error(e)
            })

        return () => {
            console.log('aborting request', method, path, data)
            controller.abort()
        }
    }, [asUser, method, setLoading, onDone, path, errorToast, data, ignoreErrors, returnAll, setError])
}
