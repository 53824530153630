import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import {Box, Stack, Tab, Tabs, Typography, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import TabPanel from "./TabPanel";
import {useCallback, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {useApiCallback} from '../../utils/Api';
import htmlToPlainString from '../../utils/htmlToString';
import {resizeToContentCardContainer} from './GridLayoutValues';


export default function CompanyAssetAndSoftwareLicenses({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = useCallback((event, newValue) => {
        setTabIndex(newValue);
        setTimeout(() => resizeToContentCardContainer(ref.current))
    },[]);

    const { assets=[], licenses=[], links={} , metadata={}} = data || {};

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Company Asset and Software Licenses"
            icon={<DevicesOutlinedIcon/>}
            iconColor={theme.palette.tiles.green}
            headerValues={[
                { heading: 'Assets', value: assets?.length },
                { heading: 'Licenses', value: licenses?.length },
            ]}
            footerLinks={[{
                text: `Request New ${tabIndex === 0 ? 'Asset' : 'Licenses'}`,
                href: links[tabIndex === 0 ? 'request_new_asset' : 'request_new_license']?.uri
            }]}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Stack gap='16px'>
                    <Tabs value={tabIndex} centered onChange={handleChange} aria-label="Your assets and licenses"
                        TabIndicatorProps={{ sx: { backgroundColor: theme.palette.dea.turquoise } }}>
                        {['assets', 'licenses'].map((name, i) => (
                            <Tab label={name} key={name} index={i} />
                        ))}
                    </Tabs>
                    <TabPanel value={tabIndex} index={0} >
                        <Stack gap='12px'>
                            <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gridTemplateRows='1fr' gap='12px'>
                                {assets?.map((asset, i) => {
                                    const name = htmlToPlainString(asset.name);
                                    return (
                                        <Stack key={i} gap='6px' alignItems='center' padding='0 12px' textAlign='center'>
                                            <Box width='56px' height='56px' border='4px solid #F9F9F9' borderRadius='1000px' overflow='hidden' src={asset.icon} alt={name} component='img' />
                                            <Typography fontSize='12px'>{name}</Typography>
                                            <Typography fontSize='10px' fontWeight='bold'>{asset.start_date}</Typography>
                                        </Stack>
                                    )
                                })}
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} >
                        <Stack gap='12px'>
                            <Stack gap='6px'>
                                {licenses?.map((license, i) => (
                                    <Box key={i} display='flex' gap='6px' justifyContent='space-between' padding='0 12px'>
                                        <Typography fontSize='12px'>{htmlToPlainString(license.name)}</Typography>
                                        <Typography fontSize='12px' fontWeight='500'>
                                            {license.start_date ? dayjs(license.start_date).format('MMM YYYY') : 'N/A'}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    </TabPanel>
                </Stack>

            </Stack>
        </CardContainer>
    )
}