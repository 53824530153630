import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { minutesToTime } from '../../utils/ymd';
import CardContainer from "./CardContainer";
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function PilotInformation({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {pilot_information = {}, links = {}, metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Your Flights"
            icon={<FlightTakeoffIcon/>}
            iconColor={theme.palette.tiles.yellow}
            headerValues={[{ heading: 'Total flights this month', value: pilot_information?.total_flights_month }]}
            footerLinks={[{ text: 'View Web SRP', href: links?.view_web_srp?.uri }]}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Typography fontSize='12px' fontWeight='bold'>
                    My Latest information
                </Typography>
                <Stack gap='6px'>
                    <Box display='flex' justifyContent='space-between' gap='12px'>
                        <Typography fontSize='12px'>Total block-to-block time this calendar month</Typography>
                        <Typography fontSize='12px' fontWeight='500'>{minutesToTime(pilot_information?.total_block_to_block_minutes_month)}</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' gap='12px'>
                        <Typography fontSize='12px'>My most visited airports</Typography>
                        <Typography fontSize='12px' fontWeight='500'>
                            {pilot_information?.most_visited_airports?.map(ap => ap.airport).join(', ')}
                        </Typography>
                    </Box>
                </Stack>
                <Typography fontSize='12px' fontWeight='bold'>
                    Latest SRP's flown
                </Typography>
                {pilot_information?.latest_srps?.map(srp => (
                    <Box display='flex' justifyContent='space-between' gap='12px' key={srp.srp_number}>
                        <Typography fontSize='12px'>{srp.aircraft.registration}</Typography>
                        <Typography fontSize='12px' component='a' href={`/flapp/#/srp/${srp.aircraft.registration}/${srp.srp_number}`} color='inherit' sx={{textDecoration: 'none', ':hover': {color: 'dea.turquoise'}}}>{srp.srp_number}</Typography>
                        <Typography fontSize='12px'>{srp.task_type}</Typography>
                        <Typography fontSize='12px'>
                            {srp.date ? dayjs(srp.date).format('MMM DD') : 'N/A'}
                        </Typography>

                    </Box>
                ))}

            </Stack>
        </CardContainer >
    )
}