import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {convertToZulu} from '../../utils/ymd';

export default function CAMOUpdates({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {updates = [], last_update = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='CAMO Updates'
            icon={<PrecisionManufacturingOutlinedIcon />}
            iconColor={theme.palette.tiles.orange}
            headerValues={[{heading: 'Last update', value: convertToZulu(last_update)}]}
            metadata={metadata}
        >
            <Box sx={{fontSize: '12px'}}>
                {updates.length > 0 ? (
                    <Stack gap='6px'>
                        {updates.map((update) => (
                            <Box key={update} display='flex' gap='4px'>
                                <Box>{update}</Box>
                            </Box>
                        ))}
                    </Stack>
                ) : (
                    <Box textAlign='center'>No CAMO updates available</Box>
                )}
            </Box>
        </CardContainer>
    )
}