import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Box, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import google_light from "./../app-icons/google-light.svg"
import google_dark from "./../app-icons/google-dark.svg"
import hibob_icon from "./../app-icons/bob.svg"
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import { useGlobalAppTheme } from '../../utils/useGlobalAppTheme';
import htmlToPlainString from '../../utils/htmlToString';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function Tasks({ apiPath }) {
    const googleLogo = useGlobalAppTheme().globalAppTheme === 'light' ? google_light : google_dark;
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {task_information = {}, links = {}, metadata = {}} = data || {}
    const {tasks = []} = task_information;

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            error={error}
            data={data}
            title='Tasks'
            icon={<TaskOutlinedIcon/>}
            iconColor={theme.palette.tiles.green}
            headerValues={[{heading: 'Total tasks', value: task_information?.total_task_count}]}
            footerLinks={[
                {text: 'See more Google Tasks', href: links?.google_tasks?.uri},
                {text: 'See more HiBob Tasks', href: links?.hibob_tasks?.uri}
            ]}
            metadata={metadata}
        >
            <Box component='table' aria-label="Table of tasks" width='100%'>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color={theme.palette.gray["500"]}>
                        <Box component='th'></Box>
                        <Box padding='0 3px 3px' textAlign='left' component='th'>TASK NAME</Box>
                        <Box padding='0 3px 3px' textAlign='left' component='th'>NOTES</Box>
                        <Box padding='0 0 3px 0' textAlign='right' component='th'>DUE DATE</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {tasks.map((row) => (
                        <Box
                            component='tr'
                            key={row.task_name}
                            fontSize='12px'
                            color={theme.palette.dea.arcticSilver}
                            textAlign='left'
                        >
                            <Box padding='3px 0' component='td' display='flex' alignItems='center'>
                                <img src={row.task_origin === 'Google' ? googleLogo : hibob_icon} alt={row.task_origin} width='16' height='16' />
                            </Box>
                            <Box padding='3px' component='td' fontWeight='700' >{row.task_name}</Box>
                            <Box padding='3px' component='td'>
                                {htmlToPlainString(row.task_details).substring(0, 20)}...
                            </Box>
                            <Box padding='3px 0' component='td' fontWeight='medium' textAlign='right'>{row['Task due date']}</Box>

                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}