import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import {Box, Link, Stack, useTheme} from "@mui/material";
import CardContainer, {CardText} from "./CardContainer";
import { minutesToTime } from '../../utils/ymd';
import { useApiCallback } from '../../utils/Api';
import { useEffect, useRef, useState } from 'react';
import {formatDateWithRemainingTime} from '../../utils/formatDateWithRemainingTime';
import {useLoginStore} from "../../Login";
import {resizeToContentCardContainer} from './GridLayoutValues';
import dayjs from 'dayjs';

const isLessThanSixMonths = (date) => dayjs(date).diff(dayjs(), 'month') < 6

const UDVal = (props) => <CardText {...props} textAlign='right'/>
const UDKey = (props) => <CardText fontWeight='500' {...props}/>
const UDItem = ({red, ...props}) => <Box display='flex' justifyContent='space-between' gap='12px'{...props} backgroundColor={props.red ? 'error.main' : ''} padding={props.red ? '2px 4px' : ''} borderRadius={props.red ? '4px' : ''} />

export default function UserDetails({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);
    const {user} = useLoginStore()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {user_details = {}, links = {}, metadata={} } = data || {}

    const {passport_number} = user_details
    const {passport_expiry_date} = user_details
    const {leave_approver} = user_details
    const {security_clearance_expiry_date} = user_details
    const {total_block_to_block_minutes} = user_details
    const {sick_days_taken} = user_details
    const {total_passenger_flights} = user_details
    const {holiday_remaining, holiday_total} = user_details

    const {employment_start_date} = user_details

    let employed_since = ''
    if (employment_start_date) {
        const d = new Date(employment_start_date)
        employed_since = `${d.getMonth()+1}`.padStart(2, '0') + '/' + d.getFullYear()
    }

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title={user_details?.name || user}
            icon={<BadgeOutlinedIcon/>}
            iconColor={theme.palette.tiles.red}
            headerValues={[{heading: 'Employed since', value: employed_since}]}
            footerLinks={[{text: 'View HiBob', href: links?.view_hibob?.uri}]}
            metadata={metadata}
        >
            <Stack gap='6px' sx={{fontSize:'12px'}}>
                {passport_number ? (
                    <UDItem>
                        <UDKey>Passport Number</UDKey>
                        <UDVal>{passport_number}</UDVal>
                    </UDItem>
                ) : ''}
                {passport_expiry_date ? (
                    <UDItem red={isLessThanSixMonths(passport_expiry_date)}>
                        <UDKey>Passport expiry date</UDKey>
                        <UDVal>{formatDateWithRemainingTime(passport_expiry_date,['y','mo','d'])}</UDVal>
                    </UDItem>
                ) : ''}
                <UDItem>
                    <UDKey>Annual leave remaining/total</UDKey>
                    <UDVal>{holiday_remaining}/{holiday_total}</UDVal>
                </UDItem>
                <UDItem>
                    <UDKey>Sick days</UDKey>
                    <UDVal>{sick_days_taken}</UDVal>
                </UDItem>
                {total_passenger_flights ? (
                    <UDItem>
                        <UDKey>Total Flights as Passenger</UDKey>
                        <UDVal>{total_passenger_flights}</UDVal>
                    </UDItem>
                ) : ''}
                {total_block_to_block_minutes ? (
                    <UDItem>
                        <UDKey>Total Flights Hours (Block to Block)</UDKey>
                        <UDVal>{minutesToTime(total_block_to_block_minutes)}</UDVal>
                    </UDItem>
                ) : '' }
                {security_clearance_expiry_date ? (
                    <UDItem red={isLessThanSixMonths(security_clearance_expiry_date)}>
                        <UDKey>Security clearance expires</UDKey>
                        <UDVal>{formatDateWithRemainingTime(security_clearance_expiry_date,['y', 'mo','d','h'])}</UDVal>
                    </UDItem>
                ) : ''}
                {leave_approver ? (
                    <UDItem>
                        <UDKey>Leave Approver</UDKey>
                        <UDVal><Link sx={{lineHeight:'100%'}} href={`mailto:${leave_approver.email}`}>{leave_approver?.name}</Link></UDVal>
                    </UDItem>
                ) : ''}
            </Stack>
        </CardContainer>
    )
}