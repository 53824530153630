/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { useGlobalAppTheme } from "./utils/useGlobalAppTheme";
import { useEffect, useMemo } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

/* @uncommon - @FIXME needs removing for integration to common-code */
const palette = {
    mode: 'dark',
    divider: '#00000033',
    dividerInverted: '#ffffff80',
    dea: {
        arcticSilver: '#e4e4e9',
        chipFill: '#71788280',
        dashboardCellBg: '#567195',
        fieldOutline: '#717882bf',
        invertedJetBlack: '#ffffff',
        invertedTurquoise: '#00ce9b',
        jetBlack: '#000000',
        marineBlue: '#8391be',
        stealthGrey: '#34373b',
        stealthGreyText: '#34373b',
        transparentWhiteBlack: '#00000033',
        turquoise: '#5fe9c7',
    },
    esao: {
        black: '#0d0416',
        silver: '#7b9aa9',
    },
    native: {
        scrollbarBg: '#eeeeee',
    },
    action: {
        active: '#ffffff8f',
        disabled: '#ffffff61',
        disabledBackground: '#ffffff1f',
        focus: '#ffffff1f',
        hover: '#ffffff14',
    },
    background: {
        default: '#121212',
        paper: {
            elevation0: '#121212',
            elevation1: '#1e1e1e',
            elevation2: '#232323',
            elevation3: '#252525',
            elevation4: '#272727',
            elevation5: '#2a2a2a',
            elevation6: '#2c2c2c',
            elevation7: '#2c2c2c',
            elevation8: '#2e2e2e',
            elevation9: '#2e2e2e',
            elevation10: '#313131',
            elevation11: '#313131',
            elevation12: '#333333',
            elevation13: '#333333',
            elevation14: '#333333',
            elevation15: '#333333',
            elevation16: '#363636',
            elevation17: '#363636',
            elevation18: '#363636',
            elevation19: '#363636',
            elevation20: '#383838',
            elevation21: '#383838',
            elevation22: '#383838',
            elevation23: '#383838',
            elevation24: '#383838',
        },
    },
    common: {
        white: {
            focus: '#ffffff1f',
            focusVisible: '#ffffff4d',
            hover: '#ffffff14',
            main: '#ffffff',
        },
    },
    components: {
        avatarFill: '#757575',
    },
    elevation: {
        outlined: '#e0e0e0',
    },
    error: {
        contrast: '#ffffff',
        dark: '#c62828',
        focusVisible: '#d32f2f4d',
        hover: '#d32f2f0a',
        main: '#F44336',
        outlinedBorder: '#d32f2f80',
    },
    info: {
        contrast: '#000000',
        dark: '#01579b',
        focusVisible: '#0288d14d',
        hover: '#0288d10a',
        main: '#29B6F6',
        outlinedBorder: '#0288d180',
    },
    primary: {
        contrast: '#000000',
        dark: '#1e88e5',
        focusVisible: '#2196f34d',
        hover: '#2196f30a',
        main: '#00ce9b',
        outlinedBorder: '#2196f380',
        selected: '#2196f314',
    },
    secondary: {
        contrast: '#000000',
        dark: '#7b1fa2',
        focusVisible: '#9c27b04d',
        hover: '#9c27b00a',
        main: '#8391BE',
        outlinedBorder: '#9c27b080',
    },
    success: {
        contrast: '#000000',
        dark: '#1b5e20',
        focusVisible: '#2e7d324d',
        hover: '#2e7d320a',
        main: '#66BB6A',
        outlinedBorder: '#2e7d3280',
    },
    text: {
        disabled: '#ffffff61',
        focusVisible: '#ffffff4d',
        primary: '#ffffffde',
        secondary: '#fff',
    },
    tiles: {
        blue: '#5886e9',
        darkBlue: '#2862dc',
        darkestBlue: '#0f3b99',
        green: '#75d475',
        lightBlue: '#a1d6fc',
        lighterBlue: '#99baff',
        lightestBlue: '#adc8ff',
        orange: '#ff9705',
        red: '#ff5005',
        red2: '#D32F2F',
        teal: '#37cdc0',
        yellow: '#ffc505',
    },
    warning: {
        contrast: '#000000',
        focusVisible: '#ef6c004d',
        hover: '#ef6c000a',
        main: '#FFA726',
        outlinedBorder: '#ef6c0080',
    },
    gray: {
        '100': '#F5F5F5',
        '300': '#E0E0E0',
        '500': '#BDBDBD',
    },

    feature: {}, // We need because import Autocomplete from '@mui/material/Autocomplete' accesses this values
}
const paletteLight = {
    mode: 'light',
    divider: '#ffffff80',
    dividerInverted: '#00000033',
    dea: {
        arcticSilver: '#1b1b16',
        chipFill: '#9ba2ae80',
        dashboardCellBg: '#c7daf4',
        fieldOutline: '#9ba2ae80',
        invertedJetBlack: '#000000',
        invertedTurquoise: '#5fe9c7',
        jetBlack: '#ffffff',
        marineBlue: '#5e72b0',
        stealthGrey: '#f2f2f7',
        stealthGreyText: '#34373b',
        transparentWhiteBlack: '#ffffff80',
        turquoise: '#00ce9b',
    },
    esao: {
        black: '#0d0416',
        silver: '#7b9aa9',
    },
    native: {
        scrollbarBg: '#616161',
    },
    action: {
        active: '#0000008f',
        disabled: '#00000061',
        disabledBackground: '#0000001f',
        focus: '#0000001f',
        hover: '#0000000a',
    },
    background: {
        default: '#ffffff',
        paper: {
            elevation0: '#ffffff',
            elevation1: '#ffffff',
            elevation2: '#ffffff',
            elevation3: '#ffffff',
            elevation4: '#ffffff',
            elevation5: '#ffffff',
            elevation6: '#ffffff',
            elevation7: '#ffffff',
            elevation8: '#ffffff',
            elevation9: '#ffffff',
            elevation10: '#ffffff',
            elevation11: '#ffffff',
            elevation12: '#ffffff',
            elevation13: '#ffffff',
            elevation14: '#ffffff',
            elevation15: '#ffffff',
            elevation16: '#ffffff',
            elevation17: '#ffffff',
            elevation18: '#ffffff',
            elevation19: '#ffffff',
            elevation20: '#ffffff',
            elevation21: '#ffffff',
            elevation22: '#ffffff',
            elevation23: '#ffffff',
            elevation24: '#ffffff',
        },
    },
    common: {
        white: {
            focus: '#ffffff1f',
            focusVisible: '#ffffff4d',
            hover: '#ffffff14',
            main: '#ffffff',
        },
    },
    components: {
        avatarFill: '#757575',
    },
    elevation: {
        outlined: '#0000001f',
    },
    error: {
        contrast: '#ffffff',
        dark: '#d32f2f',
        focusVisible: '#f443364d',
        hover: '#f4433614',
        main: '#D32F2F',
        outlinedBorder: '#f4433680',
    },
    info: {
        contrast: '#ffffff',
        dark: '#0288d1',
        focusVisible: '#29b6f64d',
        hover: '#29b6f614',
        main: '#0288D1',
        outlinedBorder: '#29b6f680',
    },
    primary: {
        contrast: '#ffffff',
        dark: '#42a5f5',
        focusVisible: '#90caf94d',
        hover: '#90caf914',
        main: '#00ce9b',
        outlinedBorder: '#90caf980',
        selected: '#90caf929',
    },
    secondary: {
        contrast: '#ffffff',
        dark: '#ab47bc',
        focusVisible: '#ce93d84d',
        hover: '#ce93d814',
        main: '#5E72B0',
        outlinedBorder: '#ce93d880',
    },
    success: {
        contrast: '#ffffff',
        dark: '#388e3c',
        focusVisible: '#66bb6a4d',
        hover: '#66bb6a14',
        main: '#2E7D32',
        outlinedBorder: '#66bb6a80',
    },
    text: {
        disabled: '#00000061',
        focusVisible: '#0000004d',
        primary: '#000',
        secondary: '#000',
    },
    tiles: {
        blue: '#85a7ef',
        darkBlue: '#5482e3',
        darkestBlue: '#144dc8',
        green: '#9ee19e',
        lightBlue: '#d3ecfe',
        lighterBlue: '#ccdcff',
        lightestBlue: '#e0eaff',
        orange: '#ffac38',
        red: '#ff824d',
        teal: '#60d7cd',
        yellow: '#ffd138',
    },
    warning: {
        contrast: '#ffffff',
        focusVisible: '#ffa7264d',
        hover: '#ffa72614',
        main: '#EF6C00',
        outlinedBorder: '#ffa72680',
    },
    gray: {
        '100': '#F5F5F5',
        '300': '#E0E0E0',
        '500': '#BDBDBD',
    },
    feature: {}, // We need because import Autocomplete from '@mui/material/Autocomplete' accesses this values
}

const spacing = (factor) => {
    const values = [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];
    const index = Math.floor(factor);
    const currentSpace = values[index];
    const nextSpace = values[index + 1] || currentSpace * 2;
    const space = currentSpace + (nextSpace - currentSpace) * (factor - index);
    return `${space}px`;
}

const components = {
    MuiCssBaseline: {
        styleOverrides: (theme) => ({
            body: {
                margin: 0,
                fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
                    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                backgroundColor: theme.palette.dea.stealthGrey,
            },
            '::-webkit-scrollbar': {
                width: '0.7em',
            },
            '::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.default,
                width: '0.8em',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.dea.chipFill,
                borderRadius: '8px',
                width: '0.7rem',
            },
            '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: theme.palette.dea.dashboardCellBg,
            },
            // CALENDAR STYLING
            '.fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary': {
                display: "none"
            },
            'h2.fc-toolbar-title': {
                display: "none"
            },
            '.fc-col-header-cell': {
                background: theme.palette.background.default,
                textTransform: "uppercase",
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "11px",
                fontWeight: "600",
                lineHeight: "13.41px",
                textAlign: "center",
            },
            '#root > div > main > div > div.MuiGrid-root.MuiGrid-container > div:nth-child(2) > h5': {
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "24px",
                lineHeight: "29.26px",
                textAlign: "center",
            },
            '.fc-daygrid-day-frame': {
                background: theme.palette.background.paper
            },
            '.fc-theme-standard td, .fc-theme-standard th': {
                borderColor: theme.palette.background.default
            },
            '.fc-theme-standard .fc-scrollgrid': {
                borderColor: theme.palette.background.default
            },
            '.fc-scroller': {
                backgroundColor: `${theme.palette.background.default} !important`
            },
            '.fc-event-main': {
                lineHeight: "14px"
            },
            '.fc-daygrid-day-top': {
                flexDirection: "row !important",
                //fontFamily: "Roboto"
            },
            '.fc-daygrid-bg-harness .fc-event-title': {
                textAlign: "right"
                //margin-top: 36px
            },
            '.fc-daygrid-event-harness, .fc-daygrid-event-harness-abs': {
                //this makes each day-event bar sit at the bottom
                top: "25px !important"
            },
            '.fc-daygrid-event': {
                borderRadius: "40px !important",
                marginTop: "10px"
            },
            '.fc .fc-bg-event': {
                backgroundColor: `${theme.palette.background.paper}`,
                //opacity: 0.9
            },
            '.fc-day-today': {
                backgroundColor: "#00000000 !important"
            },
            '.fc .fc-bg-event .fc-event-title': {
                fontFamily: "'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "18.75px",
                marginTop: "5px;",
                //color: theme.palette.text.primary,

            },
            '#root > div.MuiBox-root > main > div > div.MuiBox-root > div > div > div > button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                textTransform: "capitalize",
                fontFamily: "'Inter', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            },
            '.fc-daygrid-block-event': {
                pointerEvents: "none",
                height: "14px",
            },
            '#ts-pay-summary': {
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
            },
            '#ts-pay-summary>strong': {
                fontWeight: "800"
            },
            '.event-submitted': {
                pointerEvents: "none",
                marginTop: "-55px !important",
                fontSize: "18px",
                textAlign: "center",
                '&:hover': {
                    background: "none"
                }
            },
            '.event-submitted-flight': {
                pointerEvents: "none",
                marginTop: "-40px !important",
                textAlign: "center",
                '&:hover': {
                    background: "none"
                }
            },
            '.fc-daygrid-event-dot': {
                display: "none"
            },
            code: {
                fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
            },
            '.MuiAutocomplete-option': {
                color: theme.palette.dea.arcticSilver,
            },
            '.MuiSwitch-track': {
                backgroundColor: theme.palette.grey[500],
            },
        }),
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                marginTop: 0,
                marginBottom: 0,
                marginRight: 4,
            }
        },
        defaultProps: {
            margin: 'dense',
            color: 'feature',
        },
    },
    MuiMenu: {
        styleOverrides: {
            root: ({ theme }) => ({
                // backgroundColor: theme.palette.background.default,
            }),
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: ({ theme }) => ({
                "&:hover": {
                    backgroundColor: theme.palette.dea.stealthGrey,
                }
            }),
        }
    },
    MuiCheckbox: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&.Mui-checked:not(.Mui-disabled)': {
                    color: theme.palette.feature.main,
                }
            })
        }
    },
    MuiTextField: {
        defaultProps: {
            size: "small",
            margin: 'dense',
            variant: "outlined",
            color: 'feature',
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiAutocomplete-inputRoot': {
                    color: theme.palette.dea.arcticSilver,
                },
                '& .MuiAutocomplete-input': {
                    color: theme.palette.dea.arcticSilver,
                },
                '& .MuiFormLabel-root': {
                    color: theme.palette.dea.arcticSilver,
                },
            }),
        },
    },
    MuiLink: {
        styleOverrides: {
            root: ({ theme }) => ({
                /* make those links easier to press on mobile devices */
                color: theme.palette.dea.arcticSilver,
                lineHeight: '32px',
                [theme.breakpoints.down('sm')]: {
                    lineHeight: '24px',
                },
            }),
        }
    },
    MuiRouterLink: {
        styleOverrides: {
            root: ({ theme }) => ({
                /* make those links easier to press on mobile devices */
                color: theme.palette.dea.arcticSilver,
            }),
        }
    },
    // divider
    MuiDivider: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderColor: theme.palette.dividerInverted,
            }),
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                '&.Mui-focused': {
                    // color: '#666',
                },
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: ({ theme }) => ({
                "& .MuiDrawer-modal": {
                    /* apparently this isnt working. */
                },
                "& .MuiDrawer-paper": {
                    zIndex: 900,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.dea.stealthGrey,
                    color: theme.palette.dea.arcticSilver,
                    backgroundImage: 'none',
                },
                "& .MuiListItem-root": {
                    backgroundColor: theme.palette.dea.stealthGrey,
                },
                "& .MuiListItemButton-root": {
                    '.MuiListItemIcon-root': {
                        color: 'currentColor',
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.dea.turquoise,
                        color: theme.palette.dea.stealthGreyText,
                    },
                },
                "& .MuiList-root": {
                    backgroundColor: theme.palette.dea.stealthGrey,
                },
            }),
        },
    },
    MuiDataGrid: {
        defaultProps: {
            rowHeight: 30,
        },
        styleOverrides: {
            root: ({ theme }) => ({
                marginTop: 20,
                marginBottom: 20,
                color: theme.palette.secondary.contrast,
                borderColor: theme.palette.secondary,
                backgroundColor: theme.palette.secondary,
            }),
            row: ({ theme }) => ({
                "&.Mui-selected": {
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    },
                },
                "&:hover": {
                    backgroundColor: theme.palette.secondary.light,
                },
            }),
            cell: ({ theme }) => ({
                borderColor: theme.palette.secondary,
            }),
            columnHeaders: ({ theme }) => ({
                borderColor: theme.palette.secondary,
            }),
            columnHeader: ({ theme }) => ({
                borderColor: theme.palette.secondary,
            }),
        }
    },
    MuiModal: {
        styleOverrides: {
            root: ({ theme }) => ({
                '>.ModalInner': {
                    backgroundColor: theme.palette.primary.main,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    padding: '20px',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    boxShadow: '0 0 20px black',
                    maxWidth: '95vw',
                    maxHeight: '95vh',
                    overflow: 'hidden',
                    component: "form",
                    noValidate: 'on',
                    autoComplete: 'off',
                },
                backgroundColor: 'transparent',
                borderWidth: '2px',
                borderSyle: 'solid',
                borderColor: theme.palette.primary.dark
            }),
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                marginRight: 4,
                color: 'currentColor'
            }
        },
        defaultProps: {
            variant: 'contained',
        },
        responsiveFontSizes: {}
    },
    MuiFilledInput: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiFormHelperText: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiIconButton: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiInputBase: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiInputLabel: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                backgroundColor: palette.background.default,
            }
        },
    },
    MuiList: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.primary.light,
                '&.user-menu-list': {
                    padding: '0px !important',
                    margin: '0px !important',
                    borderRadius: '25px',
                }
            }),
        },
        defaultProps: {
            dense: 'true',
        },
    },
    MuiOutlinedInput: {
        defaultProps: {
            size: 'small',
            margin: 'dense',
        },
        styleOverrides: {
            root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    // border: 'black 1.5px solid',
                },
                '&.Mui-focused': {
                    // border: 'red 1px solid',
                },
            }
        }
    },
    MuiFab: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiTable: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiAppBar: {
        styleOverrides: {
            root: ({ theme }) => ({
                /* @see https://github.com/mui/material-ui/issues/41602 */
                backgroundImage: 'none',
                backgroundColor: theme.palette.dea.invertedJetBlack,
                color: theme.palette.dea.jetBlack,
                shadows: 'none',
            })
        }
    },
    MuiToolbar: {
        defaultProps: {
            variant: 'dense',
        },
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.dea.arcticSilver,
                fontSize: '12px',
                '&.Mui-selected': {
                    color: theme.palette.dea.turquoise,
                },
            })
        }
    },
}

const breakpoints = {
    values: {
        xs: 0,
        sm: 630,
        md: 834,
        lg: 1280,
        xl: 1728,
    },
}

const darkTheme = responsiveFontSizes(createTheme({
    palette,
    spacing,
    components,
    breakpoints,
}));
const lightTheme = responsiveFontSizes(createTheme({
    palette: paletteLight,
    spacing,
    components,
    breakpoints,
}))

export default function Main({ children }) {
    const { globalAppTheme, loadGlobalAppTheme } = useGlobalAppTheme()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(() => (globalAppTheme === 'dark' || (globalAppTheme === 'system' && prefersDarkMode))
        ? darkTheme
        : lightTheme
        , [globalAppTheme, prefersDarkMode]);

    useEffect(() => {
        const f = e => {
            if (e.key === 'global_app_theme') {
                loadGlobalAppTheme()
            }
        }
        window.addEventListener("storage", f)
        return () => window.removeEventListener('storage', f)
    }, [loadGlobalAppTheme])

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
