import { Box } from "@mui/material";

export default function TabPanel({ children, value, index, ...other }) {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`card-tabpanel-${index}`}
            aria-labelledby={`card-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}