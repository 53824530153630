import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import {Alert, Box, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useEffect, useRef, useState} from 'react';
import {useApiCallback} from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {formatDate} from '../../utils/formatDateWithRemainingTime';

export default function DevicesAndSecurity({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {total_vulnerabilities_count = 0, has_non_compliant_efb = false, devices = {}, links = {}, metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            error={error}
            data={data}
            title='Devices And Security'
            icon={<DevicesOutlinedIcon/>}
            iconColor={theme.palette.tiles.red2}
            headerValues={[{heading: 'Total vulnerabilities', value: total_vulnerabilities_count}]}
            footerLinks={[
                {text: 'Device policy', href: links?.device_policy?.uri},
                {text: 'About BYOD', href: links?.about_byod?.uri},
                {text: 'EFB Policy', href: links?.efb_policy?.uri}
            ]}
            metadata={metadata}
        >
            <Box>
                {has_non_compliant_efb && <Alert variant='filled' severity='error' sx={{marginBottom:'12px'}}>EFB out of compliance</Alert>}
                <Box component='table' aria-label="Table of devices and security" width='100%'>
                    <Box component='thead' fontSize='10px'>
                        <Box component='tr' color={theme.palette.gray["500"]}>
                            <Box padding='0 3px 0 0' textAlign='left' component='th'>DEVICE</Box>
                            <Box padding='0 3px' textAlign='center' component='th'>LAST SYNC</Box>
                            <Box padding='0 3px' textAlign='center' component='th' title='ENCRYPTED'>ENC...</Box>
                            <Box padding='0 3px' textAlign='center' component='th'>STATE</Box>
                            <Box padding='0 0 0 3px' textAlign='right' component='th' title='VULNERABILITIES'>VULN...</Box>
                        </Box>
                    </Box>
                    <Box component='tbody'>
                        <DevicesAndSecurityValues rows={devices.company} title='Company Owned'/>
                        <DevicesAndSecurityValues rows={devices.byod} title='Bring Your Own Device (BYOD)'/>
                    </Box>
                </Box>
            </Box>
        </CardContainer>
    )
}

function DevicesAndSecurityValues({title, rows}) {
    const theme = useTheme();

    if (rows?.length === 0) return null

    return (
        <>
            <Box component='td' colSpan='5' fontSize='12px' fontWeight='500' textAlign='left' padding='9px 3px 3px 0'>{title}</Box>

            {rows?.map((row) => (
                <Box
                    component='tr'
                    key={row.name}
                    fontSize='12px'
                    color={theme.palette.dea.arcticSilver}
                    textAlign='center'
                >
                    <Box padding='3px 3px 3px 0' component='td'>
                        <Box textAlign='left' width='120px' overflow='hidden' textOverflow='ellipsis' sx={{whiteSpace:'nowrap'}}>
                            {row.name}
                        </Box>
                    </Box>
                    <Box padding='3px' component='td'>{formatDate(row.last_sync)}</Box>
                    <Box padding='3px' component='td' color={theme.palette[row.encrypted ? 'success' : 'error'].main}>
                        {row.encrypted ? 'Yes' : 'No'}
                    </Box>
                    <Box padding='3px' component='td'>{row.state}</Box>
                    <Box padding='0 0 0 3px' component='td' textAlign='right' color={row.vulnerabilities ? 'error.main' : ''}>
                        {row.vulnerabilities}
                    </Box>
                </Box>
            ))}
        </>
    )
}