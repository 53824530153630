/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useCallback, useMemo, useState} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import Grid from "@mui/material/Grid";
import DataGrid from "../component/DataGrid";
import ErrorBlock from "../component/ErrorBlock";

export default function Query() {
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState(localStorage.getItem('lastquery') || '')

    const run = useApiCallback('POST', `/api/query`, {setLoading, onDone: setResult})
    const data = useMemo(() => result.map(r => ({id: Math.random(), ...r})), [result]);

    const columns = useMemo(() => {
        if (!result.length) {
            return []
        }
        return Object.keys(result[0]).map(key => ({
            field: key,
            display: 'flex',
            headerName: key,
            // renderCell: cell => <div>{cell.value}</div>,
        }));
    }, [result])

    const runClick = useCallback(() => {
        setResult([])
        localStorage.setItem('lastquery', query)
        run({query})
    }, [query, run])

    return <Stack sx={{fontSize: {xs: '0.8em', sm: '0.9em', md: '1em'}}}>
        <Grid container sx={{maxWidth: '800px'}}>
            <Grid item xs={10} p={1}>
                <TextField
                    label="Query"
                    InputLabelProps={{shrink: true}}
                    multiline
                    disabled={loading}
                    value={query}
                    onChange={event => setQuery(event.target.value)}
                    InputProps={{
                        style: {fontFamily: 'Monospace'},
                    }}
                    sx={{width: '100%'}}
                />
            </Grid>
            <Grid item xs={2} p={1}>
                <Button onClick={runClick}>Run</Button>
            </Grid>
        </Grid>
        <ErrorBlock><DataGrid
            wrapperStyle={{height: 'calc(100dvh - 140px)'}}
            rows={data} columns={columns} />
        </ErrorBlock>
    </Stack>
}
