/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useState, useEffect, useMemo, useCallback} from "react";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useLoginStore} from "../Login";
import {useNavigate} from "react-router-dom";
import {useToastStore} from "../Toast";


const buttonStyle = {
    textTransform: 'none',
    margin: '1px',
}


export default function Admins() {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const {canUser, id: me} = useLoginStore()
    const [username, setUsername] = useState('')
    const navigate = useNavigate()

    const fetchUsers = useApiCallback('GET', `/api/admins`, {setLoading, onDone: setUsers})
    const addUser = useApiCallback('POST', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })
    const removeUser = useApiCallback('DELETE', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })
    const [config, setConfig] = useState(null)
    const fetchConfig = useApiCallback('POST', `/api/config/fetch`, {setLoading, onDone: setConfig})
    const getConfig = useApiCallback('GET', `/api/config/safe`, {setLoading, onDone: setConfig})
    useEffect(getConfig, [getConfig])
    useEffect(fetchUsers, [fetchUsers])
    const {showError, showSuccess, showWarning, showInfo} = useToastStore()

    const toastTest = useCallback(() => {
        showSuccess('test success, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showError('test error, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showWarning('test warning, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showInfo('test info, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
    }, [showError, showInfo, showSuccess, showWarning])

    const links = useMemo(() => (
        [
            {action: () => navigate('/api/errors'), name: 'API Errors'},
            {action: () => navigate('/api/groups'), name: 'API Groups'},
            {action: () => navigate('/api/users'), name: 'API Users'},
            {action: () => navigate('/api/server'), name: 'API Server Info'},
            {action: toastTest, name: 'Toast Test'},
        ].filter(({path}) => canUser('GET', path))
         .map(({action, name}) => <Button onClick={action}>{name}</Button>)
    ), [canUser, navigate, toastTest])

    return <Stack justifyContent="center" alignItems="start">
        {canUser('POST', '/api/admins')
            ? <Stack direction="row" alignItems="center">
                <TextField
                    label="Name"
                    InputLabelProps={{shrink: true}}
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                    sx={{width: '300px', margin: 2}}
                />
                <Button disabled={!username} onClick={() => addUser()}>Add</Button>
                <Button disabled={!username} onClick={() => removeUser()}>Delete</Button>
                <Button onClick={() => setUsername(me)}>Me</Button>
            </Stack>
            : ''}
        <br/>
        {users.map(k => <Button key={k} sx={buttonStyle} onClick={() => setUsername(k)}>{k}</Button>)}
        <br/>
        <br/>
        {canUser('POST', '/api/config/fetch') ? <>
            <Typography>This runs every 10 minutes, but can be handy to run this after changing a parameter store
                variable
                for immediate effect.</Typography>
            <Button disabled={loading} onClick={() => fetchConfig()} color="warning">Re-fetch Config from Parameter
                Store</Button>
            <pre style={{fontSize: '0.9em', borderRadius: '10px', padding: 3}}>
                {config && JSON.stringify(config, null, 4)}
            </pre>
        </> : ''}
        {links}
    </Stack>
}
