import { useTheme } from '@emotion/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Link, Box, Typography, Stack, Divider, Grid } from '@mui/material';

import retfordCover from '../../images/Retford-cover.jpg';
import linkedin from '../app-icons/linkedin.svg';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {useEffect, useRef} from 'react';

export default function TopImageTile() {
    const theme = useTheme();
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => resizeToContentCardContainer(ref.current, 10000/* Set very to work for all screens*/))
    }, [])

    return (
        <Grid 
            ref={ref}
            container 
            padding={{md: '16px'}} 
            component='section' 
            backgroundColor='dea.jetBlack' 
            borderRadius='8px' 
            overflow='hidden' 
            boxShadow={`0px 2px 10px ${theme.palette.dea.chipFill}`}>

            <Grid item backgroundColor={{xs: 'gray.500', md: 'transparent'}} height={{md: '100%'}} xs={12} md={3} lg={2} className='drag-cancel' sx={{cursor:'initial'}}>
                <Box component='img' width={{xs: 'auto', md: 'calc(100% - 16px)'}} height={{xs: '224px', md: 'auto'}} margin={{xs: 'auto', md: 0}} src={retfordCover} alt='Retford: every other Monday' display='block' borderRadius={{ md: '8px' }}/>
            </Grid>

            <Grid item display='flex' flexDirection='column' justifyContent={{md: 'space-between'}} gap='16px' padding={{xs: '16px', md: '0px'}} xs={12} md={9} lg={10} sx={{cursor:'initial'}}>
                <Stack gap='12px'>
                    <Box display='flex' justifyContent='space-between'>
                        <Typography fontWeight='700'>It’s men’s health week</Typography>
                        <Link href="https://www.linkedin.com/company/diamond-executive-aviation-ltd/mycompany/" target='_blank'>
                            <img src={linkedin} alt="linkedin icon" width="32" height="32"/>
                        </Link>
                    </Box>
                    <Stack gap='6px' sx={{ '& .MuiLink-root': { color: 'dea.turquoise' } }}>
                        <Typography fontSize='12px' fontWeight='600' fontStyle='italic'>Did you know there's men-only weekly peer support groups in our area and UK wide?</Typography>

                        <Typography fontSize='12px' >In Sam's Name (Bassetlaw region only) & Andy's Man Club (UK wide & online) offer a confidential space where men can open up about the storms in their lives and stop the stigma.</Typography>

                        <Typography fontSize='12px' fontStyle='italic'>'A BUNCH OF BLOKES HAVING A CHAT OVER A BREW & BISCUIT.'</Typography>

                        <Typography fontSize='12px' >Andy's Man Club: 7pm every Monday. <Link>Your local venue and more info here</Link>.</Typography>

                        <Typography fontSize='12px' >
                            In Sam's Name in Retford: every other Monday. <Link>More info here</Link>. In Sam's Name at Ollerton FC - Football training and talk sessions. Every 2 weeks from 28th June. <Link>More info here</Link>.
                        </Typography>
                        <Box className='drag-handle' display='hidden'/>
                    </Stack>
                </Stack>

                <Stack gap='12px'>
                    <Divider />
                    <Stack gap='12px'>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography>Operating System Update Guidance</Typography>
                            <Link
                                target='_blank'
                                rel='noopener noreferrer'
                                href=''
                                display='flex'
                                alignItems='center'
                                sx={{ textDecoration: 'none', ':hover': { color: 'dea.turquoise' } }}
                                gap='2px'>
                                <Typography component='span' fontSize='10px'>Read more</Typography>
                                <ArrowForwardOutlinedIcon sx={{ width: 16, height: 16 }} />
                            </Link>
                        </Stack>
                        <Divider />
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography>Charitable Events</Typography>
                            <Link
                                target='_blank'
                                rel='noopener noreferrer'
                                href=''
                                display='flex'
                                alignItems='center'
                                sx={{ textDecoration: 'none', ':hover': { color: 'dea.turquoise' } }}
                                gap='2px'>
                                <Typography component='span' fontSize='10px'>Read more</Typography>
                                <ArrowForwardOutlinedIcon sx={{ width: 16, height: 16 }} />
                            </Link>
                        </Stack>
                        <Divider />
                    </Stack>
                </Stack>
            </Grid>

        </Grid >
    )
}