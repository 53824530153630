/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import create from 'zustand'
import {useCallback} from "react";

export const useToastStore = create((set) => ({
    popups: [],
    clear: () => set(() => ({popups: []})),
    remove: (obj) => set((state) => ({popups: state.popups.filter(a => a !== obj)})),
    show: (text, severity) => set((state) => {
        const key = new Date().getTime()
        const _new = {text, severity, key}
        _new.timer = setTimeout(() => state.remove(_new), 8000)
        return {popups: [_new, ...state.popups]}
    }),
    freeze: (obj) => set((state) => {
        const popups = state.popups;
        if (obj.timer) {
            clearTimeout(obj.timer)
            obj.timer = null
        }
        popups.filter(p => p !== obj).push(obj);
        return {popups}
    }),
    showSuccess: (text) => set((state) => {
        state.show(text, 'success')
        return {}
    }),
    showInfo: (text) => set((state) => {
        state.show(text, 'info')
        return {}
    }),
    showWarning: (text) => set((state) => {
        state.show(text, 'warning')
        return {}
    }),
    showError: (text) => set((state) => {
        state.show(text, 'error')
        return {}
    }),
}))

export default function Toast() {
    const msg = useToastStore()
    const clickToClose = useCallback(p =>
        <sub style={{position: 'absolute', bottom: 0, right: 0, marginRight: 5}}
             onClick={() => msg.remove(p)}>(click here to hide)</sub>
    , [msg])

    return (
        <Stack sx={{width: '100%'}}>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                sx={{marginTop:'35px'}}
                open={!!msg.popups.length}
            >
                <div>
                    {msg.popups.map(p => (
                        <Alert
                            key={p.key}
                            severity={p.severity || 'error'}
                            variant="filled"
                            sx={{minWidth: '40vw', width: '100%', position: 'relative', mb:1}}
                            onDoubleClick={() => msg.remove(p)}
                            onClick={() => msg.freeze(p)}
                        >
                            {p.text}
                            {p.timer ? '' : clickToClose(p)}
                        </Alert>
                    ))}
                </div>
            </Snackbar>
        </Stack>
    )
}
