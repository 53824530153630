import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { Box, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import formatCurrency from '../formatCurrency';
import { useApiCallback } from '../../utils/Api';
import { useEffect, useRef, useState } from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function CompanyInformation({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {company_information = {}, metadata = {} } = data || {};
    const {currency = 'GBP'} = company_information;
    const {total_revenue_cents = 0} = company_information

    const totalRevenue = formatCurrency(currency, total_revenue_cents, false)
    const {total_employees = 0} = company_information;
    const {total_aircraft = 0} = company_information;
    const {unique_airports_month_count = 0} = company_information;
    const {total_flights_month_count = 0} = company_information;

    const items = [
        ['Total employees in DEA', total_employees],
        ['Total aircrafts (asset register)', total_aircraft],
        ['Total unique airports visited (since June 1st)', unique_airports_month_count],
        ['Total flights flown this month', total_flights_month_count],
        ['DEA Aviation Company Number', '05728943'],
        ['DEA Aviation VAT Number', 'GB 895622678'],
        ['European SAO (ESAO) Company Number', 'C 85813'],
        ['European SAO (ESAO) VAT Number', '27461623']
    ]

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            error={error}
            title="Company information"
            icon={<CardTravelOutlinedIcon/>}
            iconColor={theme.palette.tiles.lightBlue}
            headerValues={[{ heading: 'Total revenue', value: totalRevenue }]}
            metadata={metadata}
        >
            <Stack gap='6px'>
                {items.map(([key, value]) => (
                    <Box display='flex' justifyContent='space-between' gap='12px' key={key}>
                        <Typography fontSize='12px'>{key}</Typography>
                        <Typography fontSize='12px' textTransform='capitalize' fontWeight='500'>{value}</Typography>
                    </Box>
                ))}

            </Stack>
        </CardContainer >
    )
}
