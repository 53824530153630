import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function OnCall({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {on_call = [], total_on_call = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='On Call'
            icon={<PhonelinkSetupOutlinedIcon/>}
            iconColor={theme.palette.tiles.blue}
            headerValues={[{heading: 'Total On Call', value: total_on_call}]}
            metadata={metadata}
        >
            {on_call.length ? <Stack gap='6px' fontSize='12px'>
                {on_call.map(({chat_url, email, name, task}) => (
                    <Box key={email} display='flex' justifyContent='space-between' alignItems='center'>
                        <Box>{name}</Box>
                        <Box display='flex' gap='12px' alignItems='center'>
                            <Box>{task}</Box>
                            <Box component='a' href={`mailto:${email}`} sx={{textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'currentColor', ':hover': {color: 'dea.turquoise'}}} title={`Email ${name}`}>
                                <EmailOutlinedIcon sx={{width: 16, height: 16}}/>
                            </Box>
                            <Box
                                target='_blank'
                                rel='noopener noreferrer'
                                component='a' href={chat_url}
                                sx={{textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'currentColor', ':hover': {color: 'dea.turquoise'}}}
                                title={`Chat with ${name}`}>
                                <ChatOutlinedIcon sx={{width: 16, height: 16}}/>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Stack> : <Box textAlign='center'>No On Call</Box>}
        </CardContainer>
    )
}