import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { Box, Chip, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import formatCurrency from '../formatCurrency';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

const chipColor = {
    'Settled': {backgroundColor: 'success.main', color: 'success.contrast'},
    'Authorised': {backgroundColor: 'info.main', color: 'info.contrast'},
    'Cancelled': {backgroundColor: 'warning.main', color: 'warning.contrast'},
    'Declined': {backgroundColor: 'error.main', color: 'error.contrast'},
    'Dispute Failed': {backgroundColor: 'action.selected', color: 'text.primary'},
    'Dispute Opened': {backgroundColor: 'action.selected', color: 'text.primary'},
    'Dispute Won': {backgroundColor: 'action.selected', color: 'text.primary'},
    'Moved': {backgroundColor: 'secondary.main', color: 'secondary.contrast'},
    'Unknown': {backgroundColor: 'secondary.main', color: 'secondary.contrast'},
}
const currencySymbol = {
    "GBP": "£",
    "EUR": "€",
}

export default function CompanyCard({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const [selectedCard, setSelectedCard] = useState(0);
    const handleChangeCard = useCallback((event, newValue) => {
        setSelectedCard(newValue)
        setTimeout(() => resizeToContentCardContainer(ref.current))
    }, [])
    const { cards=[], links={}, metadata={} } = data || {}

    const total_spend_month = useMemo(() => {
        try {
            return formatCurrency(cards[selectedCard]?.currency, cards[selectedCard]?.total_spend_month_cents || 0)
        } catch (e) {}
    }, [cards, selectedCard])

    const remaining_balance_cents = useMemo(() => {
        try {
            return formatCurrency(cards[selectedCard]?.currency, cards[selectedCard]?.remaining_balance_cents || 0)
        } catch (e) {}
    }, [cards, selectedCard])
    

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Company Card"
            icon={<CardTravelOutlinedIcon/>}
            iconColor={theme.palette.tiles.orange}
            headerValues={[
                {
                    heading: `${new Date().toLocaleString('default', {month: 'long'})} spend`,
                    value: total_spend_month,
                },
                {
                    heading: 'Remaining Balance',
                    value: remaining_balance_cents,
                },
            ]}
            footerLinks={[
                {
                    text: 'Soldo',
                    href: links?.view?.uri
                },
                {
                    text: 'Company Card Policy',
                    href: links?.company_card_policy?.uri,
                },
                {
                    text: 'Report transaction',
                    href: links?.report_unknown_transaction?.uri,
                    showIcon: false
                },
            ]}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Typography fontSize='12px' fontWeight='bold'>
                    Latest transactions
                </Typography>

                {(cards.length <= 1)
                    ? <Box component='table'><TabContent card={cards[0]}/></Box>
                    : <Stack gap='12px'>
                        {cards.length > 1 ? (
                            <Tabs value={selectedCard} onChange={handleChangeCard} aria-label="Company card of transactions"
                                TabIndicatorProps={{sx: {backgroundColor: theme.palette.dea.turquoise}}}
                            >
                                {cards?.map(({name, currency}, i) => (
                                    <Tab label={`${name} (${currencySymbol[currency]})`} key={name} index={i} />
                                ))}
                            </Tabs>
                        ) : ''}
                        {cards?.map((card, i) => (
                            <TabPanel value={selectedCard} index={i} key={card.name} component='table'>
                                <TabContent card={card}/>
                            </TabPanel>
                        ))}
                    </Stack>
                }
            </Stack>
        </CardContainer >
    )
}


function TabPanel({ children, value, index, ...other }) {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`card-tabpanel-${index}`}
            aria-labelledby={`card-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

function TabContent ({card}) {
    return (
        <Box gap='6px' component='tbody'>
            {card?.recent_transactions?.map(transaction => (
                <Box component='tr' key={transaction.description}>
                    <Box component='td' padding='3px 0'>
                        <Typography fontSize='12px'>{transaction.description}</Typography>
                    </Box>
                    <Box component='td' padding='3px 0' textAlign='center'>
                        <Chip label={transaction.status} size='small' sx={{...chipColor[transaction.status]}} />
                    </Box>
                    <Box component='td' padding='3px 0' fontSize='12px' fontWeight='500' textAlign='right'>
                        {formatCurrency(transaction.currency, transaction.amount_cents)}
                    </Box>
                </Box>
            ))}
        </Box>
    )    
}