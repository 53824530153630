import {useTheme} from '@emotion/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import {Link, Box, Typography, Stack, IconButton} from '@mui/material';
import PlaceholderCard from "./PlaceholderCard";
import {forwardRef, useEffect, useRef, useState} from "react";
import ApiMetadata from './ApiMetadata';
import {defaultHeight} from './GridLayoutValues';

export const CardText = (props) => <Typography sx={{fontSize:'12px'}} {...props}/>
export const CardLink = (props) => <Link sx={{lineHeight:'12px'}} {...props}/>

const CardContainer = forwardRef(function CardContainer(props, ref) {
    const {img, loading, data, icon, iconColor, title, headerValues, footerLinks, footerText, children, style, metadata, height = '100%', showSeeMore = true} = props;
    const theme = useTheme();
    const mainContentContainerRef = useRef(null);
    const [heightDifference, setHeightDifference] = useState(0);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (!data) return;
        const firstChild = mainContentContainerRef.current.firstChild;
        if (!firstChild) return;
        setHeightDifference(firstChild?.offsetHeight - mainContentContainerRef.current.offsetHeight);
    }, [data]);

    function handleShowMore() {
        const gridItem = ref.current.closest('.grid-stack-item')

        if (seeMore) {
            window.myGrid.update(gridItem, {h: defaultHeight})
            setSeeMore(false);
            return;
        }
        const h = defaultHeight + Math.ceil(heightDifference / 10) + 3
        console.log('h', h);
        
        window.myGrid.update(gridItem, {h})
        setSeeMore(true);
    }

    const cl = 'tile-' + title.replaceAll(' ', '-')
    if (loading) {
        return <PlaceholderCard
            className={cl}
            ref={ref}
            title={title}
            icon={icon}
            iconColor={iconColor}
        />
    }

    return (
        <Box component='section'
            className={cl}
             ref={ref}
             backgroundColor='dea.jetBlack'
             sx={{...style}}
             borderRadius='8px'
             overflow={img ? 'hidden' : ''}
             boxShadow={`0px 2px 10px ${theme.palette.dea.chipFill}`}
             height={height}
        >
            <Stack gap='12px' padding='0 16px 16px' height={height}>
                <Box component='header' display='flex' justifyContent='space-between' gap='4px' >
                    <Box display='flex' gap='12px'>
                        <Box width='60px'
                            height='60px'
                            flexShrink={0}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            backgroundColor={iconColor}
                            borderRadius='14px'
                            sx={{transform: 'translateY(-6px)', cursor: 'grab'}}
                            className='drag-handle'
                        >{icon}</Box>
                        <Typography component='span' paddingTop='12px' fontWeight='bold'>{title}</Typography>
                    </Box>
                    <Box display='flex' gap='6px' paddingTop='12px' sx={{overflow:'hidden'}} flexShrink='0'>
                        {headerValues && headerValues.map(({heading, value}) => (
                            <Stack key={heading} gap='12' alignItems='flex-end' textAlign='right'>
                                <Typography fontSize='12px'>{heading}</Typography>
                                <Typography fontWeight='bold'>{value}</Typography>
                            </Stack>
                        ))}
                    </Box>
                </Box>

                <Box paddingTop='12px' height='100%' ref={mainContentContainerRef}
                     borderTop={`1px solid ${theme.palette.dividerInverted}`}
                     sx={{overflow: {xs: 'auto', sm: 'hidden'}}}
                     position={'relative'}
                >
                    {children}
                    {heightDifference > 0 && showSeeMore && <Box position='absolute' width='100%' bottom='-4px' left='0' justifyContent='center'
                        sx={{background: `linear-gradient(0deg, ${theme.palette.dea.jetBlack} 50%, rgba(0,0,0,0) 100%)`}} display={{xs: 'none', sm: 'flex'}}>
                        <IconButton aria-label="see more" onClick={handleShowMore}>
                            <ArrowDropDownCircleIcon sx={{transform: seeMore ? 'rotate(180deg)' : ''}}/>
                        </IconButton>
                    </Box>}
                </Box>

                {(footerLinks || metadata) ?
                    <Box display='flex'
                         alignItems='center'
                         justifyContent='space-between'
                         gap='12px' paddingTop='12px'
                         borderTop={`1px solid ${theme.palette.dividerInverted}`}
                    >
                        <Box display='flex' alignItems='center' gap='12px'>
                            {footerLinks?.length && footerLinks?.map(({text, href, showIcon = true}) => (
                                <Link
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={href}
                                    key={text}
                                    display='flex'
                                    alignItems='center'
                                    sx={{textDecoration: 'none', ':hover': {color: 'dea.turquoise'}}}
                                    gap='2px'>

                                    <Typography component='span' fontSize='10px'>{text}</Typography>
                                    {showIcon ? <ArrowForwardOutlinedIcon sx={{width: 16, height: 16}}/> : ''}
                                </Link>
                            ))}
                        </Box>
                        <Box display='flex' alignItems='center' gap='4px'>
                            {footerText && <Typography component='span' fontSize='10px'>{footerText}</Typography>}
                            {metadata && <ApiMetadata metadata={metadata}/>}
                        </Box>
                    </Box>
                : ''}
            </Stack>
        </Box>
    )
});
export default CardContainer;
