/**
 * Format currency
 * @param {string} currency Currency code like 'USD'
 * @param {number} amountCents In cents like 1000 for $10.00
 * @param {boolean}   showCents whether to show cents in output
 * @returns string
 */
export default function formatCurrency(currency, amountCents, showCents=true) {
    if (amountCents === null || amountCents === undefined) {
        return '-'
    }
    const denomination = parseFloat(amountCents) / 100.0
    const formatter = new Intl.NumberFormat(navigator.languages, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: showCents ? 2 : 0,
        maximumFractionDigits: showCents ? 2 : 0,
    })
    return formatter.format(denomination)
}
