import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {Box, Chip, Typography, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useRef, useEffect, useState} from 'react';
import {useApiCallback} from '../../utils/Api';
import PersonAvatar from "../PersonAvatar";
import Grid from "@mui/material/Grid";
import {HidingBox, useResizeObserver} from "../../utils/useResizeObserver";
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function CompanyProjects({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        if (data) resizeToContentCardContainer(ref.current,10000/* Set very to work for all screens*/)
    }, [data])

    const {projects_information, metadata = {}} = data || {}
    const {width} = useResizeObserver(ref)

    const ProjName = (props)    => <Box {...props}/>
    const ProjState = (props)   => <Box {...props}/>
    const ProjManager = (props) => <HidingBox hide={width < 400} {...props}/>
    const Sponsor = (props)     => <HidingBox hide={width < 500} {...props}/>
    const DueDate = (props)     => <HidingBox hide={width < 600} {...props}/>
    const LastUpdate = (props)  => <HidingBox hide={width < 700} {...props}/>

    return (
        <CardContainer
            loading={loading}
            error={error}
            ref={ref}
            title="Company Projects"
            icon={<AccountTreeIcon/>}
            iconColor={theme.palette.tiles.yellow}
            headerValues={[{heading: 'Total projects', value: projects_information?.total}]}
            metadata={metadata}
        >
            <Box component='table' aria-label="Table of tasks" width='100%'>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color={theme.palette.gray["500"]}>
                        <ProjName padding='0 0 3px 0' textAlign='left' component='th'>PROJECT NAME</ProjName>
                        <Sponsor padding='0 3px 3px' textAlign='left' component='th'>PROJECT SPONSOR</Sponsor>
                        <ProjManager padding='0 3px 3px' textAlign='left' component='th'>PROJECT MANAGER</ProjManager>
                        <DueDate padding='0 3px 3px' textAlign='left' component='th'>DUE DATE</DueDate>
                        <LastUpdate padding='0 3px 3px' textAlign='left' component='th'>LAST UPDATE</LastUpdate>
                        <ProjState padding='0 0 3px 0' textAlign='right' component='th'>STATUS</ProjState>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {projects_information?.projects?.map((row) => (
                        <Box
                            component='tr'
                            key={row.name}
                            fontSize='12px'
                            color={theme.palette.dea.arcticSilver}
                            textAlign='left'
                        >
                            <ProjName padding='3px 0' component='td' fontWeight='500'>{row.name}</ProjName>

                            <Sponsor padding='3px' component='td' gap='6px' display='flex' alignItems='center'>
                                {row.project_sponsor &&
                                    <Typography fontWeight='bold' fontSize='12px'>{row.project_sponsor}</Typography>
                                }
                            </Sponsor>

                            <ProjManager padding='3px' component='td'>
                                <Grid container justifyContent="left" alignItems="center">
                                    <Grid item mr={2}>
                                        <PersonAvatar
                                            person={row.project_manager.email}
                                            sx={{borderRadius: 100, height:32, width:32}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {row.project_manager.name}
                                    </Grid>
                                </Grid>
                            </ProjManager>

                            <DueDate padding='3px' component='td'>
                                {row.due_date ? row.due_date : 'N/A'}
                            </DueDate>

                            <LastUpdate padding='3px 0' component='td' fontWeight='medium'>
                                {row.last_update ? row.last_update : 'N/A'}
                            </LastUpdate>

                            <ProjState padding='3px' component='td' textAlign='right'>
                                <Chip label={row.on_plan_state} fontSize='11px' size='small' color={row.on_plan_state === 'On Plan' ?'success':'error'} />
                            </ProjState>
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}
