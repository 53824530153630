import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {Box, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function CustomerContracts({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 10000, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {contracts = [], total_contracts = '0', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Customer Contracts'
            icon={<ContentCopyOutlinedIcon/>}
            iconColor={theme.palette.tiles.darkBlue}
            headerValues={[{heading: 'Total contracts', value: total_contracts}]}
            metadata={metadata}
        >
            <Box component='table' aria-label="Table of locations and temperature" width='100%' fontSize='12px' textAlign='center'>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color={theme.palette.gray["500"]} sx={{textWrap: 'nowrap'}}>
                        <Box component='th' padding='0 0 3px 0' textAlign='left' sx={{display: {xs: 'none', md: 'table-cell'}}}>OPERATOR</Box>
                        <Box component='th' padding='0 3px 3px'>CONTRACT</Box>
                        <Box component='th' padding='0 3px 3px' sx={{display: {xs: 'none', sm: 'table-cell'}}}>A/C REG</Box>
                        <Box component='th' padding='0 3px 3px'>LOCATION</Box>
                        <Box component='th' padding='0 3px 3px' sx={{display: {xs: 'none', sm: 'table-cell'}}}>CALLSIGN</Box>
                        <Box component='th' padding='0 0 3px 0'>TIMES (ZULU)</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {contracts?.map((contract) => (
                        <Box component='tr' key={contract?.contract + contract?.location} fontWeight='medium'>
                            <Box component='td' padding='3px 0' textAlign='left' sx={{display: {xs: 'none', sm: 'table-cell'}}}>{contract?.operator}</Box>
                            <Box component='td' padding='3px'>{contract?.contract}</Box>
                            <Box component='td' padding='3px 0' sx={{display: {xs: 'none', sm: 'table-cell'}}}>{contract?.aircraft_registration}</Box>
                            <Box component='td' padding='3px 0'>{contract?.location}</Box>
                            <Box component='td' padding='3px 0' sx={{display: {xs: 'none', sm: 'table-cell'}}}>{contract?.callsign}</Box>
                            <Box component='td' padding='3px 0'>{contract?.times}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}