import WavesIcon from '@mui/icons-material/Waves';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {convertToZulu} from '../../utils/ymd';

const colorsCoding = {
    '9': 'tiles.red2',
    '8': 'tiles.red',
    '7': 'tiles.orange',
    '6': 'tiles.yellow',
    '5': 'tiles.lightBlue',
    '4': 'tiles.lighterBlue',
    '3': 'tiles.lightestBlue',
    '2': 'tiles.green',
    '1': 'tiles.teal',
}

export default function SeaStateinOperatingArea({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {sea_reports = [], last_update = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Sea State in Operating Area'
            icon={<WavesIcon/>}
            iconColor={theme.palette.primary.dark}
            headerValues={[{heading: 'Last update', value: convertToZulu(last_update)}]}
            metadata={metadata}
        >
            <Stack gap='6px' sx={{fontSize: '12px'}}>
                {sea_reports.length && sea_reports.map(report => (
                    <Box display='flex' justifyContent='space-between'>
                        <Box fontWeight='500'>{report?.area}</Box>
                        <Box color={colorsCoding[report?.code || '5']}>Sea State ({report?.code}) {report?.description}</Box>
                    </Box>
                ))}
            </Stack>
        </CardContainer>
    )
}