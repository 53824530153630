import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function MeetingsandVisitors({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {meetings = [], visitors = [], total_visitors = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Meetings and Visitors'
            icon={<PersonPinCircleOutlinedIcon/>}
            iconColor={theme.palette.tiles.teal}
            headerValues={[{heading: 'Total Visitors', value: total_visitors}]}
            metadata={metadata}
        >
            <Stack gap='6px' textAlign='center' fontSize='12px'>
                <Box component='table' aria-label='Table of visitors' width='100%' fontSize='12px' textAlign='center' sx={{tableLayout: 'fixed'}}>
                    <Box component='thead' fontSize='10px'>
                        <Box component='tr' color="gray.500" sx={{textWrap: 'nowrap'}}>
                            <Box component='th' padding='0 0 3px 0' textAlign='left'>VISITORS</Box>
                            <Box component='th' padding='0 0 3px 0' textAlign='center'>COMPANY</Box>
                            <Box component='th' padding='0 0 3px 0' textAlign='right'>SEEING</Box>
                        </Box>
                    </Box>
                    <Box component='tbody'>
                        {visitors?.map((visitor) => (
                            <Box component='tr' key={visitor?.visitor} fontWeight='medium'>
                                <Box component='td' padding='3px 0' textAlign='left'>{visitor?.visitor}</Box>
                                <Box component='td' padding='3px' textAlign='center'>{visitor?.company}</Box>
                                <Box component='td' padding='3px 0' textAlign='right'>{visitor?.seeing}</Box>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box component='table' aria-label='Table of meetings' width='100%' fontSize='12px' textAlign='center' sx={{tableLayout: 'fixed'}}>
                    <Box component='thead' fontSize='10px'>
                        <Box component='tr' color="gray.500" sx={{textWrap: 'nowrap'}}>
                            <Box component='th' padding='0 0 3px 0' textAlign='left'>MEETINGS</Box>
                            <Box component='th' padding='0 0 3px 0' textAlign='center'>LOCATION</Box>
                            <Box component='th' padding='0 0 3px 0' textAlign='right'>TIME</Box>
                        </Box>
                    </Box>
                    <Box component='tbody'>
                        {meetings?.map((meeting) => (
                            <Box component='tr' key={meeting?.meeting} fontWeight='medium'>
                                <Box component='td' padding='3px 0' textAlign='left'>{meeting?.meeting}</Box>
                                <Box component='td' padding='3px' textAlign='center'>{meeting?.location}</Box>
                                <Box component='td' padding='3px 0' textAlign='right'>{meeting?.time}</Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Stack>
        </CardContainer>
    )
}
