import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import TabPanel from "./TabPanel";
import { useCallback, useEffect, useRef, useState } from 'react';
import formatCurrency from '../formatCurrency';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';


export default function TimesheetDetails({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const handleChange = useCallback((e, newValue) => {
        setTabIndex(newValue);
        setTimeout(() => resizeToContentCardContainer(ref.current))
    },[]);

    const { timesheet_information={}, links={}, metadata={} } = data || {};
    const currency = tabIndex === 0 ? 'GBP' : 'EUR';

    const availableCurrencies = Object.keys(timesheet_information);
    if (availableCurrencies.length <= 1) {
        const currCurrency = availableCurrencies[0];
        return (
            <CardContainer
                ref={ref}
                loading={loading}
                data={data}
                error={error}
                title="Timesheet"
                icon={<EventNoteOutlinedIcon/>}
                iconColor={theme.palette.tiles.lighterBlue}
                headerValues={[
                    { heading: 'Monthly total', value: formatCurrency(currCurrency, timesheet_information[currCurrency]?.month_total_cents) },
                    { heading: 'Year-To-Date', value: formatCurrency(currCurrency, timesheet_information[currCurrency]?.year_total_cents) }
                ]}
                footerLinks={[
                    { text: 'View Timesheets', href: links?.view_timesheets?.uri },
                ]}
                metadata={metadata}
            >
                <TypeValues values={timesheet_information[currCurrency]?.by_type} currency={currCurrency} />
            </CardContainer>
        )
    }

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Timesheet"
            icon={<EventNoteOutlinedIcon/>}
            iconColor={theme.palette.tiles.lighterBlue}
            headerValues={[
                { heading: 'Monthly total', value: formatCurrency(currency, timesheet_information[currency]?.month_total_cents) },
                { heading: 'Year-To-Date', value: formatCurrency(currency, timesheet_information[currency]?.year_total_cents) }
            ]}
            footerLinks={[
                { text: 'View Timesheets', href: links?.view_timesheets?.uri },
            ]}
            metadata={metadata}
        >
            <Stack gap='12px'>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="Timesheet currencies"
                    TabIndicatorProps={{
                        sx: { backgroundColor: theme.palette.dea.turquoise }
                    }}>
                    {['GBP', 'EUR'].map((name, i) => (
                        <Tab label={name} index={i} key={name} />
                    ))}
                </Tabs>
                {['GBP', 'EUR'].map((name, i) => (
                    <TabPanel value={tabIndex} index={i} key={name} >
                        <TypeValues values={timesheet_information[name]?.by_type} currency={name} />
                    </TabPanel>
                ))}
            </Stack>
        </CardContainer>
    )
}

function TypeValues(props) {
    const { values={}, currency=null } = props
    const { deployed=null, notdeployed=null, training=null, leave=null, wfh=null, ro=null } = values
    const names = [
        { name: 'Deployed total', value: deployed },
        { name: 'Leave / Holiday total', value: leave },
        { name: 'Non-contract work total', value: notdeployed },
        { name: 'Training (as instructor) total', value: training },
        { name: 'Working From Home', value: wfh },
        { name: 'Rostered off', value: ro }
    ]
    return <Stack gap='6px'>
        {names.map(({ name, value }) => {
            return (
                <Box display='flex' justifyContent='space-between' key={name}>
                    <Typography fontSize='12px'>{name}</Typography>
                    <Typography fontSize='12px' fontWeight='500'>
                        {(value?.pay_cents && value?.pay_cents > 0) ? formatCurrency(currency, value?.pay_cents) : '-'}
                        &nbsp;
                        {(value?.pay_cents && value?.pay_cents > 0) ? '(' + value?.days + ' days)': ''}
                    </Typography>
                </Box>
            )
        })}
    </Stack>
}