import ModeOfTravelOutlinedIcon from '@mui/icons-material/ModeOfTravelOutlined';
import {Box, Divider, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import {visuallyHidden} from '@mui/utils';

export default function GamstonAirportDEAHQ({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 10000, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {test_date_zones = [], alert_news = [], news = [], metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Gamston Airport & DEA HQ'
            showSeeMore={false}
            icon={<ModeOfTravelOutlinedIcon/>}
            iconColor={theme.palette.tiles.yellow}
            metadata={metadata}
        >
            <Stack sx={{fontSize: '12px'}} gap='12px'>
                <Zones zones={test_date_zones}/>

                <Divider/>

                <ZoneText/>

                <Divider/>

                <Stack gap='6px'>
                    <News news={news}/>
                    <News news={alert_news} alert/>
                </Stack>
            </Stack>
        </CardContainer>
    )
}

function Zones({zones}) {
    return <Box component='table' aria-label="Table of zones" width='100%' textAlign='center'>
        <Box component='thead' fontSize='10px'>
            <Box component='tr' color='gray.500' sx={{textWrap: 'nowrap'}}>
                <Box component='th' padding='0 0 3px 0' textAlign='left'><Box sx={visuallyHidden}>Zone Date</Box></Box>
                <Box component='th' padding='0 0 3px 0'>Zone 1</Box>
                <Box component='th' padding='0 0 3px 0'>Zone 2</Box>
                <Box component='th' padding='0 0 3px 0'>Zone 3</Box>
                <Box component='th' padding='0 0 3px 0'>Zone 4</Box>
            </Box>
        </Box>
        <Box component='tbody' sx={{textWrap: 'nowrap'}} fontWeight='600'>
            {zones?.map(({test_date, zone_1 = '', zone_2 = '', zone_3 = '', zone_4 = ''}) => test_date &&(
                <Box component='tr' key={test_date}>
                    <Box component='td' padding='0' textAlign='left'>{test_date}</Box>
                    {zone_1 === '' && zone_2 === '' && zone_3 === '' && zone_4 === '' ? (
                        <Box component='td' colSpan={4} padding='1px'>
                            No Scheduled Testing
                        </Box>
                    ) : (
                        <>
                            <Box component='td' padding='1px' sx={{backgroundColor: zone_1 ? '#34C759' : ''}}>{zone_1}</Box>
                            <Box component='td' padding='1px' sx={{backgroundColor: zone_2 ? '#34C759' : ''}}>{zone_2}</Box>
                            <Box component='td' padding='1px' sx={{backgroundColor: zone_3 ? '#34C759' : ''}}>{zone_3}</Box>
                            <Box component='td' padding='1px' sx={{backgroundColor: zone_4 ? '#34C759' : ''}}>{zone_4}</Box>
                        </>
                    )}
                </Box>
            ))}
        </Box>
    </Box>
}

function ZoneText(){
    return <Stack gap='6px'>
        <Box><b>Zone 1</b> is the full length of the Apron. This will impact aircraft parking availability on the Southern Apron and may impact parking on the Northern Apron, subject to requirements.</Box>
        <Box><b>Zone 2</b> is the Bravo taxiway and <b>Zone 3</b> is the peri-track. This will impact in this case is simply a closure to the Bravo taxiway and some parking on the Southern Apron, dependent on the barrier position.</Box>
        <Box><b>Zone 4</b> is RWY 02/20.</Box>
        <Box><b>Zone 5</b> is taxiway Charlie between RWY 02/20 & 14/32.</Box>
    </Stack>
}

function News({news, alert}) {
    return news.map(({title = '', detail = ''}) => (
        <Box key={title} color={alert ? 'red' : ''}>{title && <span><b>{title}</b> -</span>} {detail}</Box>
    ))
}