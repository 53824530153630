/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
export function minutesToTime(i) {
    let hours = Math.floor(i / 60)
    let mins = i % 60
    if (mins < 0) {
        mins += 60
        // hours -= 1
    }
    mins += ''
    return `${hours}:${mins.padStart(2, '0')}`
}

export const formatMinutesToTime = (params) => minutesToTime(params.value)
export const renderMinutesToTime = (params) => minutesToTime(params.value)

export const formatDate = (datestr) => {
    if (!datestr) {
        return '-'
    }
    const mm = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    if (datestr instanceof Date) {
        return `${datestr.getDate()} ${mm[datestr.getMonth()+1]} ${datestr.getFullYear()}`
    }
    if (typeof(datestr) === 'string') {
        let [d,m,y] = datestr.split('-')
        m = mm[parseInt(m)]
        return `${y} ${m} ${d}`
    }
    if (typeof datestr.$isDayjsObject === 'boolean') {
        return `${datestr.$D} ${mm[datestr.$M+1]} ${datestr.$y}`
    }
    return datestr
}
export const renderDate = cell => formatDate(cell.value)

export const formatDateTime = (datestr) => {
    if (!datestr) {
        return '-'
    }
    const ymdhms = datestr.split('T', 2)
    const date = formatDate(ymdhms[0])
    const hms = ymdhms[1].split(':')
    const time = hms[0] + ':' + hms[1]
    return `${date} ${time}`
}
export const renderDateTime = (params) => (<>{formatDateTime(params.value)}</>)
