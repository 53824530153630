import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Divider, Link, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function Wellbeing({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Wellbeing"
            icon={<HealthAndSafetyIcon/>}
            iconColor={theme.palette.tiles.green}
            metadata={data?.metadata}
        >
            <Stack gap='12px' fontSize='12px' >
                <Typography fontSize='12px' textAlign='center'>
                    The wellbeing of our team members couldn't be more important to us. It's far more than just a moral obligation. The wellbeing of our people is intrinsic to our Organisational Strategy and People Plan
                </Typography>
                <Divider/>
                <Box>
                    {data?.events?.map((event,i) => {
                        
                        const dateOrTime = event.start.dateTime ? new Date(event.start.dateTime).toLocaleString() : new Date(event.start.date).toLocaleDateString()

                        return (
                            <Box key={event.title} display='flex' justifyContent='space-between' alignItems='center'>
                                <Box display='flex' gap='8px' alignItems='center'>
                                    <Box>{event.title}</Box>
                                    <Box>{dateOrTime}</Box>
                                </Box>
                                <Box textAlign='right'>
                                    <Link
                                        href={event.link.uri}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        fontWeight='500'
                                        sx={{textDecoration: 'none', ':hover': {color: 'dea.turquoise'}}}
                                    >
                                        View event {i+1}
                                    </Link>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Divider/>
                <Box display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    gap='12px'
                >
                    <Box display='flex' alignItems='center' gap='12px'>
                        <Link
                            target='_blank'
                            rel='noopener noreferrer'
                            href="#"
                            display='flex'
                            alignItems='center'
                            sx={{textDecoration: 'none', ':hover': {color: 'dea.turquoise'}}}
                            gap='2px'>
                            <Typography component='span' fontSize='10px'>Smart health #LG015194</Typography>
                            <ArrowForwardOutlinedIcon sx={{width: 16, height: 16}}/>
                        </Link>
                    </Box>
                    <Button 
                        endIcon={<ErrorOutlineIcon/>} size='small' color='error' 
                        style={{fontSize: '10px', color: theme.palette.common.white.main, fontWeight: '500'}}
                        href='https://my.dea.aero/accidentbook/'
                        target='_blank'
                        sx={{marginRight:'0px'}}
                    >
                        Report accident
                    </Button> 
                </Box> 
            </Stack>

        </CardContainer>
    )
}