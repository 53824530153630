/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {getServer} from "../utils/server";
import {useEffect, useRef} from "react";
import {create} from "zustand";
import {useServerStore} from "../utils/useServerStore";
import {useLoginStore} from "../Login";
import {useInterval} from "../utils/useInterval";

const srv = getServer().replace('https://', '')
const socketUrl = `wss://${srv}/ws`

export const useWebsocketStore = create((set) => ({
    send: null,
    setSend: (send) => set({ send }),
    lastMessage: null,
    setLastMessage: (lastMessage) => set({ lastMessage }),
}))


export default function WebSock() {
    // Discard or dont provide the WebSockConn object if user not logged in
    const {user} = useLoginStore()
    return user ? <WebSockConn/> : <></>
}


const WebSockConn = () => {
    const {setServertime, servertime} = useServerStore()
    const {setLastMessage, setSend} = useWebsocketStore()
    const didUnmount = useRef(false);
    const {sendMessage, lastMessage, readyState, getWebSocket} = useWebSocket(socketUrl, {
        shouldReconnect: () => didUnmount.current === false,
        reconnectInterval: 1000,
        reconnectAttempts: 999999,
        retryOnError: 1
    });

    useEffect(() => {
        didUnmount.current = false
        // prevent unmounted websockets from reconnecting
        return () => {didUnmount.current = true}
    }, []);

    useEffect(() => {
        const ws = getWebSocket()
        if (ws) ws.binaryType = 'arraybuffer'
    }, [getWebSocket, readyState]);

    useEffect(() => setLastMessage(lastMessage), [setLastMessage, lastMessage])

    useEffect(() => {
      const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState]
        console.log('ws connection state changed', connectionStatus)
        if (connectionStatus === 'Open') {
            setSend(sendMessage)
        } else {
            setSend(null)
        }
    }, [readyState, setSend, sendMessage])

    useInterval(() => setServertime(servertime + 1), 1000)

    /**
     * Process messages received via websocket
     */
    useEffect(() => {
        if (lastMessage === null) return

        if (typeof lastMessage.data !== 'string') {
            return
        }
        try {
            const data = JSON.parse(lastMessage.data)
            if (typeof data.servertime === 'number') {
                setServertime(data.servertime)
            }
        } catch (e) {
            console.error('failed parsing message object', e, lastMessage.data)
        }
    }, [setServertime, lastMessage]);

    return <></>;
}
