import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';

export default function LoneWorkers({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {workers = [], total_workers = '0', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Lone Workers'
            icon={<Person2OutlinedIcon/>}
            iconColor={theme.palette.tiles.green}
            headerValues={[{heading: 'Working alone', value: total_workers}]}
            metadata={metadata}
        >
            <Box component='table' aria-label='Table of lone workers' width='100%' fontSize='12px' sx={{tableLayout: 'fixed'}}>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color="gray.500"s>
                        <Box component='th' padding='0 0 3px 0' textAlign='left'>NAME</Box>
                        <Box component='th' padding='0 0 3px 0' textAlign='right'>LOCATION</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {workers?.map((worker) => (
                        <Box component='tr' key={worker?.name} fontWeight='medium'>
                            <Box component='td' padding='3px 0' textAlign='left'>{worker?.name}</Box>
                            <Box component='td' padding='3px 0' textAlign='right'>{worker?.location}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}