import {GridStack} from "gridstack";
import "gridstack/dist/gridstack.css";
import {Box} from "@mui/material";
import {defaultHeight, fullWidthTiles, gridOptions, handleLayoutSave, resizeToContentCardContainer, resizeToContentLargeTiles, situationalBriefTiles} from "./GridLayoutValues";
import RenderCard from "./RenderCard";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useResizeObserver} from "../../utils/useResizeObserver";


export default function MyGridStackSituationalBrief() {
    const ref = useRef(null);
    const grid = useRef(null)
    const resizeTimeout = useRef(null)
    const {width} = useResizeObserver(ref)

    const getCols = useMemo(function () {
        if (width < 630) return 12
        if (width < 934) return 6
        if (width < 1280) return 4
        return 3
    }, [width])

    const handleLayoutSaveCb = useCallback(() => handleLayoutSave(grid.current, 'dashboardTilesSituationalBrief'), [grid])

    useEffect(() => {
        if (!grid?.current) return
        if (width === 0) return

        grid?.current?.on('change', handleLayoutSaveCb);

        return () => grid?.current?.off('change', handleLayoutSaveCb);
    }, [grid, getCols, width, handleLayoutSaveCb]);

    const filteredTiles = useMemo(() => {
        const storedLayout = localStorage.getItem('dashboardTilesSituationalBrief');

        console.info(`Situational Brief --- Using ${storedLayout ? 'stored' : 'default'} layout, tiles:`, situationalBriefTiles.length);

        if (storedLayout) return JSON.parse(storedLayout);

        return situationalBriefTiles.map(t => ({...t, h: defaultHeight, w: fullWidthTiles.includes(t.name) ? 12 : getCols}))
    }, [getCols])

    useEffect(() => {
        window.myGridSituationalBrief = grid.current = GridStack.init({...gridOptions, class: 'situational-brief'});
        // It'll set items and we can add items to to array from the components
        resizeToContentLargeTiles(grid.current, 'resizeToContentTilesSituationalBrief');
    }, [grid])

    useEffect(() => {
        const storedWindowWidth = localStorage.getItem('windowWidth')
        if (width === 0) return
        if (storedWindowWidth === window.innerWidth.toString()) return

        function adjustTilesSize() {
            grid.current.getGridItems().forEach(item => {
                if (fullWidthTiles.includes(item.id)) return

                if (width < 630){
                    grid.current.update(item, {w: getCols})
                    resizeToContentCardContainer(item, 630, grid.current, 'resizeToContentTilesSituationalBrief')
                } else {
                    grid.current.update(item, {w: getCols, h: defaultHeight})
                }
            })
        }
        function handleResize() {
            if (resizeTimeout.current) clearTimeout(resizeTimeout.current)

            resizeTimeout.current = setTimeout(() => {
                if (grid.current) {
                    adjustTilesSize()
                    grid.current.compact()

                    if (window.innerWidth < storedWindowWidth) {
                        // We need it because there is a bug in gridstack when resizing to smaller size and it doesn't adjust some tiles and leaves empty spaces
                        console.info('Resizing to smaller size and adjusting tiles again')
                        adjustTilesSize()
                        grid.current.compact()
                    }
                }

                localStorage.setItem('windowWidth', window.innerWidth)
            }, 300)
        }

        handleResize();

        return () => {
            if (resizeTimeout.current) clearTimeout(resizeTimeout.current)
        };
    }, [width, getCols, grid]);

    return (
        <Box ref={ref} className='grid-stack situational-brief'>
            {filteredTiles.map(({name, x, y, h, w, ...opts}) => (
                <Box className='grid-stack-item' key={name} id={name} gs-h={h} gs-w={w} gs-y={y} gs-x={x}>
                    <Box className='grid-stack-item-content' style={{inset: '0px'}}>
                        <Box className='content-wrapper' padding='8px' height='100%' data-tile={name}>
                            <RenderCard name={name} opts={opts}/>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}